import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Login from '../components/Login/Login';

function ConnexionPage() {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Login />
      </div>
      <Footer />
    </div>
  );
}

export default ConnexionPage;
