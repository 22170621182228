import React, { memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './ServicesSection.module.css';

const articles = [
  {
    title: 'Le Diagnostic de Performance Énergétique (DPE)',
    description: 'Découvrez pourquoi le DPE est essentiel pour la gestion et la valorisation de vos biens immobiliers.',
    imgSrc: 'assets/dpe_information_optimise.webp',
    alt: 'Le Diagnostic de Performance Énergétique (DPE)',
    link: '/infos-dpe'
  },
  {
    title: 'La location nue',
    description: 'Apprenez les avantages et les spécificités de la location nue pour optimiser vos revenus fonciers.',
    imgSrc: 'assets/location-nu-info.webp',
    alt: 'La location nue',
    link: '/infos-location-nue'
  },
  {
    title: "La SCI à l'IS",
    description: "Comprenez les atouts fiscaux de la Société Civile Immobilière soumise à l'impôt sur les sociétés.",
    imgSrc: 'assets/sci-info-is.webp',
    alt: "La SCI à l'IS",
    link: '/fisc-immo'
  },
  {
    title: 'Le LMNP',
    description: 'Découvrez les opportunités offertes par le statut de Loueur Meublé Non Professionnel.',
    imgSrc: 'assets/lmnp-info-savoir.webp',
    alt: 'Le LMNP',
    link: '/infos-lmnp'
  }
];

const ServicesSection = memo(() => {
  const navigate = useNavigate();

  const navigateToMore = () => navigate('/infos-et-aides');

  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.mainTitle}>NOS SERVICES : UN ACCOMPAGNEMENT DE A À Z</h2>
      <h3 className={styles.subtitle}>
        Découvrez notre documentation dédiée au monde immobilier : des conseils clairs, des astuces pratiques et des
        articles approfondis pour mieux comprendre et réussir vos projets immobiliers.
      </h3>
      <div className={styles.content}>
        {articles.map((article, index) => (
          <div className={styles.articleCard} key={index}>
            <img src={article.imgSrc} alt={article.alt} className={styles.cardImage} loading="lazy" />
            <h3 className={styles.cardTitle}>{article.title}</h3>
            <p className={styles.articleDescription}>{article.description}</p>
            <Link to={article.link} className={styles.readMore}>
              Lire l&apos;article
            </Link>
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.moreButton} onClick={navigateToMore}>
          En savoir plus
        </button>
      </div>
    </section>
  );
});

ServicesSection.displayName = 'ServicesSection';

export default ServicesSection;
