import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendMailToResetPassword } from '../../services/Api';
import styles from './Login.module.css';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      try {
        await sendMailToResetPassword(email);
        toast.success('Un e-mail de réinitialisation a été envoyé !');
        navigate('/admin');
      } catch (error) {
        console.error(error);
        toast.error("Une erreur s'est produite. Veuillez réessayer.");
      }
    },
    [email, navigate]
  );

  return (
    <div className={styles.forgetPasswordContainer}>
      <h2>Mot de passe oublié</h2>
      <p>Veuillez entrer votre adresse e-mail. Nous vous enverrons un lien pour réinitialiser votre mot de passe.</p>
      <form onSubmit={handleSubmit} className={styles.forgetPasswordForm}>
        <div className={styles.inputGroup}>
          <label htmlFor="email">Adresse e-mail :</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            className={styles.inputEmailPwd}
            placeholder="Votre adresse e-mail"
          />
        </div>
        <button type="submit" className={styles.submitForgetPwd}>
          Envoyer le lien
        </button>
      </form>
    </div>
  );
};

export default ForgetPassword;
