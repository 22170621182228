import React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import InfosDpe from '../components/InfoDocuments/InfosDpe';

function DpePage() {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb
          items={[
            { label: 'Infos et aides', path: '/infos-et-aides' },
            { label: 'La DPE', path: '' }
          ]}
        />
        <InfosDpe />
      </div>
      <Footer />
    </div>
  );
}

export default DpePage;
