import React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Faq from './../components/InfoDocuments/Faq';

function FaqPage() {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb
          items={[
            { label: 'Infos et aides', path: '/infos-et-aides' },
            { label: 'FAQ', path: '' }
          ]}
        />
        <Faq />
      </div>
      <Footer />
    </div>
  );
}

export default FaqPage;
