import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { updateData } from '../../redux/artisansDetailsSlice';
import { fetchSummarySpecialities } from '../../redux/specialitiesSlice';
import {
  deleteDetailArtisan,
  getArtisanById,
  getDetailArtisanByArtisanId,
  getUserById,
  postDetailArtisan,
  updateArtisan,
  updateDetailArtisan,
  updateUser
} from '../../services/Api';
import styles from './ArtisanDetail.module.css';

const ArtisanDetailAdmin = ({ artisanId }) => {
  const dispatch = useDispatch();

  const [artisan, setArtisan] = useState(null);
  const [user, setUser] = useState(null);
  const [specialites, setSpecialites] = useState([]);
  const [details, setDetails] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [descriptionDetails, setDescriptionDetails] = useState(null);
  const [secondaryImages, setSecondaryImages] = useState([]);
  const [normeImages, setNormeImages] = useState([]);
  const [hasMounted, setHasMounted] = useState(false); // State pour vérifier si le composant est monté

  const [secondaryPhotoIndex, setSecondaryPhotoIndex] = useState(-1);
  const [normePhotoIndex, setNormePhotoIndex] = useState(-1);

  const { summarySpecialities } = useSelector(state => state.specialities);

  useEffect(() => {
    if (!summarySpecialities || summarySpecialities.length === 0) {
      dispatch(fetchSummarySpecialities());
    }
  }, [dispatch]);

  useEffect(() => {
    const initializeData = async () => {
      const arts = await getArtisanById(artisanId);
      const dets = await getDetailArtisanByArtisanId(artisanId);

      setArtisan(arts);
      setSpecialites(
        summarySpecialities.map(spe => ({
          value: spe.id,
          label: spe.metier
        }))
      );
      setDetails(dets);
    };

    if (!artisan && artisanId) {
      initializeData();
    }
  }, [artisanId, summarySpecialities]);

  useEffect(() => {
    const fetchUser = async () => {
      if (artisan?.userId) {
        const use = await getUserById(artisan.userId);
        setUser({ ...use, telephone: use.telephone ?? '', email: use.email ?? '' });
      }
    };

    if (!user) {
      fetchUser();
    }
  }, [artisan]);

  useEffect(() => {
    if (details.length > 0 && !descriptionDetails) {
      setMainImage(details.find(detail => detail.type === 'IMG_PRINCIPALE'));
      setDescriptionDetails(details.find(detail => detail.type === 'DESCRIPTION') || { description: '' });
      setSecondaryImages(details.filter(detail => detail.type === 'IMG_REALISATION'));
      setNormeImages(details.filter(detail => detail.type === 'NORME'));
    }
  }, [details]);

  // Utiliser useEffect pour lancer la maj du store seulement quand la route change
  // Cette fonction sera exécutée lorsque le composant sera démonté (page quittée)
  useEffect(() => {
    setHasMounted(true);
    return () => {
      if (hasMounted) {
        dispatch(updateData(artisanId));
      }
    };
  }, [hasMounted]);

  // Fonction de validation
  const validateNewDetail = () => {
    if (secondaryImages.length >= 10) {
      toast.warn('Vous avez atteint le nombre maximal de 10 images secondaires.');
      return false;
    }
    if (normeImages.length >= 10) {
      toast.warn('Vous avez atteint le nombre maximal de 10 normes.');
      return false;
    }
    return true;
  };

  // Gestion du changement de fichier pour les images
  const handleImageDrop = (e, type) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleSetImage(file, type);
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    handleSetImage(file, type);
  };

  const handleSetImage = (file, type) => {
    if (!validateNewDetail()) return;
    // Fonction de validation
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toast.error("La taille de l'image ne doit pas dépasser 2 Mo.");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Data = reader.result.split(',')[1];
        switch (type) {
          case 'main': {
            const imageWithoutId = {
              type: 'IMG_PRINCIPALE',
              imageDetail: base64Data,
              description: 'Image principale',
              mimeType: file.type,
              artisanId
            };
            if (mainImage) {
              const currentImage = await updateDetailArtisan(mainImage.id, imageWithoutId);
              setMainImage(currentImage);
            } else {
              const currentImage = await postDetailArtisan(imageWithoutId);
              setMainImage(currentImage);
              setArtisan({ ...artisan, details: [...artisan.details, currentImage.id] });
            }
            break;
          }
          case 'secondary': {
            const currentImage = await postDetailArtisan({
              type: 'IMG_REALISATION',
              imageDetail: base64Data,
              description: 'Image secondaire : réalisation',
              mimeType: file.type,
              artisanId
            });
            setSecondaryImages(secondaryImages => [...secondaryImages, currentImage]);
            setArtisan({ ...artisan, details: [...artisan.details, currentImage.id] });
            break;
          }
          case 'norme': {
            const currentImage = await postDetailArtisan({
              type: 'NORME',
              imageDetail: base64Data,
              description: 'Image secondaire : norme',
              mimeType: file.type,
              artisanId
            });
            setNormeImages(normeImages => [...normeImages, currentImage]);
            setArtisan({ ...artisan, details: [...artisan.details, currentImage.id] });
            break;
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = async (e, index, type, id) => {
    e.stopPropagation();
    switch (type) {
      case 'secondary': {
        setSecondaryImages(secondaryImages => secondaryImages.filter((_, i) => i !== index));
        break;
      }
      case 'norme': {
        setNormeImages(normeImages => normeImages.filter((_, i) => i !== index));
        break;
      }
    }
    await deleteDetailArtisan(id);
    setArtisan({ ...artisan, details: artisan.details.filter(detailId => detailId !== id) });
  };

  const handleCheckboxChange = field => {
    const currentValue = user.personnalDataToShow;

    const nextValue = {
      TEL: {
        TEL: 'NOTHING',
        MAIL: 'ALL',
        ALL: 'MAIL',
        NOTHING: 'TEL'
      },
      MAIL: {
        TEL: 'ALL',
        MAIL: 'NOTHING',
        ALL: 'TEL',
        NOTHING: 'MAIL'
      }
    };

    setUser({ ...user, personnalDataToShow: nextValue[field][currentValue] || field });
  };

  const handleUpdateArtisan = async () => {
    if (descriptionDetails.description.length >= 500) {
      toast.warn("Veuillez ne pas dépasser 500 caractères pour la description de l'artisan.");
      return;
    }
    if (user.telephone && !/^\d{10,15}$/.test(user.telephone)) {
      toast.warn('Numéro de téléphone invalide.');
      return;
    }
    let currentArtisan = artisan;
    if (descriptionDetails.description !== '' && descriptionDetails.artisanId) {
      await updateDetailArtisan(descriptionDetails.id, {
        ...descriptionDetails,
        description: descriptionDetails.description
      });
    } else if (descriptionDetails.description !== '') {
      await postDetailArtisan({
        type: 'DESCRIPTION',
        description: descriptionDetails.description,
        artisanId
      });
      currentArtisan.details = [...currentArtisan.details, descriptionDetails.id];
    } else if (descriptionDetails.description === '' && descriptionDetails.artisanId) {
      await deleteDetailArtisan(descriptionDetails.id);
      currentArtisan.details = currentArtisan.details.filter(item => item !== descriptionDetails.id);
    }
    setArtisan(currentArtisan);
    await updateArtisan(artisanId, currentArtisan);
    await updateUser(user.id, user);
    toast.success('Modifications enregistrées.');
  };

  return (
    <>
      {!user || !artisan || !mainImage ? (
        <div>Chargement des données de l&apos;artisan...</div>
      ) : (
        <>
          {/* Fil d'Ariane */}
          <div className={styles.breadcrumb}>
            <Link to="/" className={styles.breadcrumbLink}>
              Accueil
            </Link>{' '}
            &gt;{' '}
            <Link to="/metiers" className={styles.breadcrumbLink}>
              Métiers
            </Link>{' '}
            &gt; <span className={styles.breadcrumbCurrent}>{artisan?.entrepriseNom || 'Chargement...'}</span>
          </div>

          <div className={styles.cvContainer}>
            {!artisan ? (
              <div>Chargement des données de l&apos;artisan...</div>
            ) : (
              <>
                <header className={styles.header}>
                  <div className={styles.headerLine}>
                    <div
                      className={styles.imageContainer}
                      onClick={() => document.getElementById('file-main-new').click()}
                      onDragOver={e => e.preventDefault()}
                      onDrop={event => handleImageDrop(event, 'main')}
                      style={{
                        border: mainImage ? 'none' : '2px dashed #ccc',
                        textAlign: 'center',
                        padding: mainImage ? '0' : '20px',
                        cursor: 'pointer',
                        backgroundColor: mainImage ? 'transparent' : '#f9f9f9'
                      }}
                    >
                      {mainImage ? (
                        <img
                          src={`data:${mainImage.mimeType};base64,${mainImage.imageDetail}`}
                          alt="Présentation de l'artisan"
                          className={styles.mainImage}
                          loading="lazy"
                        />
                      ) : (
                        <p>Déposez une image ici ou cliquez pour sélectionner un fichier</p>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={event => handleFileChange(event, 'main')}
                        style={{ display: 'none' }}
                        id="file-main-new"
                      />
                    </div>
                    <h1 className={styles.entrepriseNom}>{artisan.entrepriseNom}</h1>

                    <button onClick={handleUpdateArtisan}>Enregistrer les modifications</button>
                    <Link to={`/artisan/${artisanId}`}>VOIR LE PROFIL PUBLIC</Link>
                  </div>
                  <div className={styles.basicInfo}>
                    <p>
                      <strong>Années d&apos;expérience (optionnel): </strong>
                      <input
                        type="number"
                        name="anneeExperience"
                        value={artisan.anneeExperience}
                        onChange={e => setArtisan({ ...artisan, anneeExperience: e.target.value })}
                        className={styles.inputDetail}
                      />
                    </p>

                    <p>
                      <strong>Téléphone: </strong>
                      <input
                        type="text"
                        name="tel"
                        value={user.telephone}
                        onChange={e => setUser({ ...user, telephone: e.target.value })}
                        className={styles.inputDetail}
                      />
                      <label>
                        <input
                          type="checkbox"
                          checked={user.personnalDataToShow === 'TEL' || user.personnalDataToShow === 'ALL'}
                          onChange={() => handleCheckboxChange('TEL')}
                          className={styles.checkboxDetail}
                        />
                        Montrer aux clients
                      </label>
                    </p>

                    <p>
                      <strong>Email : </strong> {user.email}
                      <label>
                        <input
                          type="checkbox"
                          checked={user.personnalDataToShow === 'MAIL' || user.personnalDataToShow === 'ALL'}
                          onChange={() => handleCheckboxChange('MAIL')}
                          className={styles.checkboxDetail}
                        />
                        Montrer aux clients
                      </label>
                    </p>

                    <p>
                      <strong>SIRET:</strong> {artisan.siret}
                    </p>
                    <p>
                      <strong>SIREN:</strong> {artisan.siren}
                    </p>
                  </div>
                </header>

                <section className={styles.locationInfo}>
                  <p>
                    <strong>Adresse:</strong> {artisan.adresse.rue}, {artisan.adresse.codePostal}{' '}
                    {artisan.adresse.ville}, {artisan.adresse.pays}
                  </p>
                  <p>
                    <strong>Rayon d&apos;activité (km): </strong>
                    <input
                      type="number"
                      name="rayonActivite"
                      value={artisan.rayonActivite}
                      onChange={e => setArtisan({ ...artisan, rayonActivite: e.target.value })}
                      max={100}
                      className={styles.inputDetail}
                    />
                  </p>
                </section>

                {descriptionDetails && (
                  <section className={styles.descriptionSection}>
                    <h2>À propos de l&apos;artisan</h2>
                    <textarea
                      value={descriptionDetails.description}
                      onChange={e => setDescriptionDetails({ ...descriptionDetails, description: e.target.value })}
                      className={styles.areaDetail}
                    />
                  </section>
                )}

                <section className={styles.specialties}>
                  <h2>Spécialités</h2>
                  <Select
                    isMulti
                    options={specialites}
                    value={specialites.filter(option => artisan.specialites.includes(option.value))}
                    onChange={selectedOptions =>
                      setArtisan(prevState => ({
                        ...prevState,
                        specialites: selectedOptions.map(option => option.value)
                      }))
                    }
                  />
                </section>

                <section className={styles.portfolio}>
                  <h2>Normes Acquises</h2>
                  <div className={styles.portfolioImages}>
                    <div
                      onClick={() => document.getElementById('file-norme-new').click()}
                      onDragOver={e => e.preventDefault()}
                      onDrop={event => handleImageDrop(event, 'norme')}
                      className={styles.portfolioImage}
                    >
                      <div className={styles.uploadBox}>
                        <p className={styles.uploadText}>
                          Déposez une image ici ou cliquez pour sélectionner un fichier
                        </p>
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={event => handleFileChange(event, 'norme')}
                        style={{ display: 'none' }}
                        id="file-norme-new"
                      />
                    </div>

                    {normeImages.map((imageDetail, index) => (
                      <div key={`${imageDetail.id}-key-${index}`} className={styles.imageWrapper}>
                        <button
                          className={styles.deleteImage}
                          onClick={event => handleDeleteImage(event, index, 'norme', imageDetail.id)}
                        >
                          ✖
                        </button>
                        <img
                          src={`data:${imageDetail.mimeType};base64,${imageDetail.imageDetail}`}
                          alt="Certification norme"
                          className={styles.portfolioImage}
                          onClick={() => setNormePhotoIndex(index)}
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </div>
                </section>

                <section className={styles.portfolio}>
                  <h2>Galerie de Réalisations</h2>
                  <div className={styles.portfolioImages}>
                    <div
                      onClick={() => document.getElementById('file-secondary-new').click()}
                      onDragOver={e => e.preventDefault()}
                      onDrop={event => handleImageDrop(event, 'secondary')}
                      className={styles.portfolioImage}
                    >
                      <div className={styles.uploadBox}>
                        <p className={styles.uploadText}>
                          Déposez une image ici ou cliquez pour sélectionner un fichier
                        </p>
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={event => handleFileChange(event, 'secondary')}
                        style={{ display: 'none' }}
                        id="file-secondary-new"
                      />
                    </div>

                    {secondaryImages.map((imageDetail, index) => (
                      <div key={`${imageDetail.id}-key-${index}`} className={styles.imageWrapper}>
                        <button
                          className={styles.deleteImage}
                          onClick={event => handleDeleteImage(event, index, 'secondary', imageDetail.id)}
                        >
                          ✖
                        </button>
                        <img
                          src={`data:${imageDetail.mimeType};base64,${imageDetail.imageDetail}`}
                          alt="Réalisation de l'artisan"
                          className={styles.portfolioImage}
                          onClick={() => setSecondaryPhotoIndex(index)}
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </div>
                </section>
              </>
            )}
          </div>
        </>
      )}

      <Lightbox
        open={!!secondaryImages[secondaryPhotoIndex]}
        close={() => setSecondaryPhotoIndex(-1)}
        slides={secondaryImages.map(image => ({
          src: `data:${image.mimeType};base64,${image.imageDetail}`,
          alt: image.type || "Image de l'artisan"
        }))}
        index={secondaryPhotoIndex}
        onIndexChange={setSecondaryPhotoIndex}
      />

      <Lightbox
        open={!!normeImages[normePhotoIndex]}
        close={() => setNormePhotoIndex(-1)}
        slides={normeImages.map(image => ({
          src: `data:${image.mimeType};base64,${image.imageDetail}`,
          alt: image.type || "Image de l'artisan"
        }))}
        index={normePhotoIndex}
        onIndexChange={setNormePhotoIndex}
      />
    </>
  );
};

ArtisanDetailAdmin.propTypes = {
  artisanId: PropTypes.string.isRequired
};

export default ArtisanDetailAdmin;
