import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkToken, userLogout } from '../../redux/authSlice';
import { fetchSpecialities, fetchSummarySpecialities } from '../../redux/specialitiesSlice';
import styles from './Header.module.css';

// Fonction utilitaire pour normaliser une chaîne
const normalizeString = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

const Header = memo(() => {
  const dispatch = useDispatch();

  // États locaux pour la gestion d'affichage
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Etat pour gérer le menu burger
  const [searchTerm, setSearchTerm] = useState('');

  // Récupération des états globaux depuis Redux
  const { userClaims } = useSelector(state => state.auth);
  const { summarySpecialities } = useSelector(state => state.specialities);

  useEffect(() => {
    if (!userClaims) {
      dispatch(checkToken());
    }
    if (!summarySpecialities || summarySpecialities.length == 0) {
      dispatch(fetchSummarySpecialities());
      // Précharger les images des spécialités
      dispatch(fetchSpecialities());
    }
  }, [dispatch, userClaims]);

  const handleMouseEnter = useCallback(() => {
    const timeout = setTimeout(() => setIsDropdownOpen(true), 100);
    return () => clearTimeout(timeout);
  }, []);

  const handleMouseLeave = useCallback(() => {
    const timeout = setTimeout(() => setIsDropdownOpen(false), 100);
    setSearchTerm('');
    return () => clearTimeout(timeout);
  }, []);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  // Fonction pour fermer le menu burger lorsqu'un lien est cliqué
  const handleLinkClick = useCallback(() => {
    setIsMobileMenuOpen(false);
  }, []);

  const handleLogoutClick = useCallback(() => {
    dispatch(userLogout());
    setIsMobileMenuOpen(false);
  }, [dispatch]);

  const handleSearch = e => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredSpecialities = useMemo(() => {
    if (summarySpecialities) {
      const normalizedSearchTerm = normalizeString(searchTerm);
      return summarySpecialities.filter(speciality =>
        normalizeString(speciality.metier).includes(normalizedSearchTerm)
      );
    }
    return [];
  }, [summarySpecialities, searchTerm]);

  return (
    <header className={styles.header}>
      {/* Lien vers la page d'accueil (Home) au clic sur le logo */}
      <div className={styles.logo}>
        <Link to="/" onClick={handleLinkClick}>
          <img src="/logov2.webp" alt="Logo" className={styles.logoImage} />
        </Link>
      </div>

      {/* Menu classique visible sur grands écrans */}
      {!isMobileMenuOpen && (
        <nav className={`${styles.nav} ${isMobileMenuOpen ? styles.open : ''}`}>
          <div className={styles.dropdownContainer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Link to="/metiers" className={styles.navLink} onClick={handleLinkClick}>
              Nos artisans
            </Link>
            {isDropdownOpen && (
              <div className={styles.dropdownMenu}>
                <input type="text" placeholder="Spécialité..." className={styles.searchInput} onChange={handleSearch} />
                {filteredSpecialities.map(speciality => (
                  <Link
                    key={speciality.id}
                    to={`/metier/${speciality.id}`}
                    className={styles.dropdownItem}
                    onClick={handleLinkClick}
                  >
                    {speciality.metier}
                  </Link>
                ))}
              </div>
            )}
          </div>
          <Link to="/map" className={styles.navLink} onClick={handleLinkClick}>
            PRÈS DE CHEZ MOI
          </Link>

          <Link to="/about" data-testid="a-propos" className={styles.navLink} onClick={handleLinkClick}>
            À PROPOS
          </Link>

          <Link to="/infos-et-aides" className={styles.navLink} onClick={handleLinkClick}>
            INFOS ET AIDES
          </Link>

          <Link to="/contact" className={styles.navLink} onClick={handleLinkClick}>
            CONTACT
          </Link>

          <Link
            to={
              !userClaims ? '/admin' : userClaims.role === 'ROLE_ADMIN' ? '/' : `/admin/artisan/${userClaims.artisanId}`
            }
            className={styles.navLink}
            onClick={handleLinkClick}
          >
            MON PROFIL PRO
          </Link>
        </nav>
      )}

      {/* Bouton "Nous rejoindre" visible dans la navigation principale sur desktop */}
      {!userClaims && (
        <Link to="/join" data-testid="nous-rejoindre" className={styles.joinButton} onClick={handleLinkClick}>
          NOUS REJOINDRE
        </Link>
      )}
      {userClaims && (
        <button className={styles.joinButton} onClick={handleLogoutClick}>
          SE DÉCONNECTER
        </button>
      )}

      <button className={`${styles.menuIcon} ${isMobileMenuOpen ? styles.open : ''}`} onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
      </button>

      {/* Menu mobile */}
      {isMobileMenuOpen && (
        <div className={`${styles.mobileMenu} ${isMobileMenuOpen ? styles.open : ''}`}>
          <Link to="/metiers" className={styles.navLink} onClick={handleLinkClick}>
            Nos artisans
          </Link>
          <Link to="/map" className={styles.navLink} onClick={handleLinkClick}>
            PRÈS DE CHEZ MOI
          </Link>
          <Link to="/about" className={styles.navLink} onClick={handleLinkClick}>
            À PROPOS
          </Link>
          <Link to="/infos-et-aides" className={styles.navLink} onClick={handleLinkClick}>
            INFOS ET AIDES
          </Link>
          <Link to="/contact" className={styles.navLink} onClick={handleLinkClick}>
            CONTACT
          </Link>
          <Link
            to={
              !userClaims ? '/admin' : userClaims.role === 'ROLE_ADMIN' ? '/' : `/admin/artisan/${userClaims.artisanId}`
            }
            className={styles.navLink}
            onClick={handleLinkClick}
          >
            MON PROFIL PRO
          </Link>
          {!userClaims && (
            <Link to="/join" data-testid="nous-rejoindre" className={styles.navLink} onClick={handleLinkClick}>
              NOUS REJOINDRE
            </Link>
          )}
          {userClaims && (
            <Link to="/" className={styles.navLink} onClick={handleLogoutClick}>
              SE DÉCONNECTER
            </Link>
          )}
        </div>
      )}
    </header>
  );
});

Header.displayName = 'Header';

export default Header;
