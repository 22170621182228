import React from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import LeafletMap from '../components/MapSection/LeafletMap';

const MapPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search'); // Récupère la valeur du paramètre "search", ou `null` si vide

  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb items={[{ label: 'Map', path: '' }]} />

        <LeafletMap searchQuery={searchQuery} />
      </div>
      <Footer />
    </div>
  );
};

export default MapPage;
