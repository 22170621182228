import React, { memo } from 'react';
import styles from './InfoDocuments.module.css';

const ComparatifRegimes = memo(() => {
  return (
    <div className={styles.pageContainer}>
      <h2 className={styles.pageTitle}>Comment choisir le bon régime de location ?</h2>
      <p className={styles.pageContent}>
        Voici un comparatif entre la location nue, le LMNP, le LMP, la SCI à l&apos;IR et la SCI à l&apos;IS.
        Ci-dessous, une analyse des avantages et inconvénients de chaque régime, en dehors de la fiscalité, pour vous
        aider à choisir en fonction de votre situation et de vos objectifs.
      </p>

      <div className={styles.comparisonList}>
        {/* Location nue */}
        <div className={styles.comparisonItem}>
          <h3>1. Location nue</h3>
          <h4>Avantages :</h4>
          <ul>
            <li>
              Simplicité de gestion : Contrats de bail standard, peu de frais liés à l&apos;équipement du logement.
            </li>
            <li>Stabilité locative : Les baux sont généralement de 3 ans renouvelables.</li>
            <li>Marché large : La demande pour les logements nus est souvent élevée, notamment pour les familles.</li>
            <li>Pas d&apos;obligation de meubler : Moins de frais initiaux et d&apos;entretien liés au mobilier.</li>
          </ul>
          <h4>Inconvénients :</h4>
          <ul>
            <li>Rendement plus faible : Les loyers sont généralement inférieurs à ceux de la location meublée.</li>
            <li>
              Pas d&apos;amortissement : Les biens loués nus ne peuvent pas être amortis, ce qui limite les
              optimisations fiscales.
            </li>
            <li>
              Charges locatives récupérables limitées : Certaines dépenses (ex. : entretien courant) sont à la charge du
              propriétaire.
            </li>
          </ul>
        </div>

        {/* LMNP */}
        <div className={styles.comparisonItem}>
          <h3>2. LMNP (Loueur Meublé Non Professionnel)</h3>
          <h4>Avantages :</h4>
          <ul>
            <li>
              Rendement locatif supérieur : Les loyers sont souvent 10 à 30 % plus élevés qu&apos;en location nue.
            </li>
            <li>
              Flexibilité des baux : Durée de bail plus courte (1 an, ou 9 mois pour les étudiants), facilitant les
              réajustements.
            </li>
            <li>
              Marché dynamique : Bonne demande pour les logements meublés dans les zones urbaines et touristiques.
            </li>
            <li>Simplicité de gestion : Moins d&apos;obligations comptables que pour le LMP ou une SCI.</li>
          </ul>
          <h4>Inconvénients :</h4>
          <ul>
            <li>
              Gestion du mobilier : Nécessité d&apos;équiper et d&apos;entretenir le logement (meubles, électroménagers,
              etc.).
            </li>
            <li>
              Durée de location plus courte : Rotation locative plus fréquente, ce qui peut entraîner des périodes de
              vacance.
            </li>
            <li>Moins adapté aux familles : Principalement recherché par les étudiants ou les jeunes actifs.</li>
          </ul>
        </div>

        {/* LMP */}
        <div className={styles.comparisonItem}>
          <h3>3. LMP (Loueur Meublé Professionnel)</h3>
          <h4>Avantages :</h4>
          <ul>
            <li>
              Optimisation fiscale accrue : Possibilité d&apos;imputer les déficits sur le revenu global et
              d&apos;amortir le bien.
            </li>
            <li>
              Exonération de la plus-value : Sous conditions, possibilité d&apos;exonérer partiellement ou totalement la
              plus-value.
            </li>
            <li>
              Professionnalisation : Statut plus adapté aux investisseurs voulant développer un patrimoine conséquent.
            </li>
          </ul>
          <h4>Inconvénients :</h4>
          <ul>
            <li>Obligations comptables : Inscription au RCS et tenue d&apos;une comptabilité rigoureuse.</li>
            <li>
              Cotisations sociales : Les revenus locatifs sont soumis aux cotisations sociales, ce qui peut augmenter
              les charges.
            </li>
            <li>Complexité administrative : Statut plus lourd à gérer que le LMNP.</li>
          </ul>
        </div>

        {/* SCI à l'IR */}
        <div className={styles.comparisonItem}>
          <h3>4. SCI à l&apos;IR (Impôt sur le Revenu)</h3>
          <h4>Avantages :</h4>
          <ul>
            <li>Flexibilité patrimoniale : Idéal pour organiser la transmission ou l&apos;achat à plusieurs.</li>
            <li>Simplicité fiscale : Les revenus locatifs sont imposés directement au niveau des associés.</li>
            <li>Adapté à la location nue : Permet de mutualiser les efforts pour des investissements locatifs.</li>
          </ul>
          <h4>Inconvénients :</h4>
          <ul>
            <li>Pas d&apos;amortissement : Les biens loués via une SCI à l&apos;IR ne peuvent pas être amortis.</li>
            <li>
              Frais de création : Constitution de la SCI et rédaction des statuts peuvent générer des frais initiaux.
            </li>
            <li>
              Responsabilité illimitée : Les associés sont responsables des dettes de la SCI proportionnellement à leur
              part.
            </li>
          </ul>
        </div>

        {/* SCI à l'IS */}
        <div className={styles.comparisonItem}>
          <h3>5. SCI à l&apos;IS (Impôt sur les Sociétés)</h3>
          <h4>Avantages :</h4>
          <ul>
            <li>
              Amortissement des biens et travaux : Permet de réduire les bénéfices imposables et de lisser les coûts.
            </li>
            <li>
              Optimisation fiscale en détention : Les charges, y compris les intérêts d&apos;emprunt, sont déductibles.
            </li>
            <li>
              Adaptée aux projets patrimoniaux : Utile pour les investissements à long terme ou les projets
              professionnels.
            </li>
          </ul>
          <h4>Inconvénients :</h4>
          <ul>
            <li>Imposition à la revente : La prise en compte des amortissements augmente la plus-value imposable.</li>
            <li>
              Complexité administrative : Nécessite une comptabilité stricte et des déclarations fiscales annuelles.
            </li>
            <li>
              Double imposition potentielle : Les bénéfices sont imposés dans la SCI, et les dividendes distribués sont
              soumis à la flat tax de 30 %.
            </li>
          </ul>
        </div>
      </div>

      <h4>Une situation = un régime adapté</h4>
      <p className={styles.pageContent}>
        Le choix dépend principalement de votre situation, de vos objectifs et du type de bien que vous souhaitez louer
        :
      </p>
      <ul className={styles.pageContent}>
        <li>
          <strong>Location nue</strong> : Idéale pour ceux qui recherchent la stabilité locative et une gestion
          simplifiée.
        </li>
        <li>
          <strong>LMNP</strong> : Convient aux investisseurs cherchant un bon rendement et une fiscalité légère, sans
          obligation professionnelle.
        </li>
        <li>
          <strong>LMP</strong> : Destiné aux propriétaires tirant des revenus élevés de la location meublée et
          souhaitant profiter d&apos;une optimisation fiscale accrue.
        </li>
        <li>
          <strong>SCI à l&apos;IR</strong> : Adaptée pour des projets familiaux ou patrimoniaux avec une fiscalité
          directe.
        </li>
        <li>
          <strong>SCI à l&apos;IS</strong> : Intéressante pour ceux qui veulent amortir les biens et les travaux, mais
          avec une vision long terme pour limiter l&apos;impact fiscal à la revente.
        </li>
      </ul>
      <p className={styles.pageContent}>
        Chaque régime a ses avantages et inconvénients, il est essentiel d&apos;évaluer vos priorités (rendement,
        fiscalité, gestion) et de consulter un expert pour choisir la meilleure solution.
      </p>
    </div>
  );
});

ComparatifRegimes.displayName = 'ComparatifRegimes';

export default ComparatifRegimes;
