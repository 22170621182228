import React, { memo } from 'react';
import { Link } from 'react-router-dom';

export const TermsOfUse = memo(() => {
  return (
    <>
      <h3>Article 1 – Objet</h3>
      <p>
        Les présentes Conditions Générales d&apos;Utilisation (CGU) ont pour objet de définir les modalités et
        conditions d&apos;utilisation de la plateforme ProxiTravaux, accessible à l&apos;adresse{' '}
        <a href="https://www.proxi-travaux.com">www.proxi-travaux.com</a>. En accédant à la plateforme,
        l&apos;utilisateur accepte sans réserve les présentes CGU.
      </p>

      <h3>Article 2 – Définitions</h3>
      <ul>
        <li>
          <strong>Utilisateur</strong> : toute personne qui accède à la plateforme pour consulter les profils
          d&apos;artisans.
        </li>
        <li>
          <strong>Artisan</strong> : tout professionnel inscrit sur ProxiTravaux pour proposer ses services.
        </li>
        <li>
          <strong>Plateforme</strong> : le site web ProxiTravaux et ses fonctionnalités.
        </li>
      </ul>

      <h3>Article 3 – Services proposés</h3>
      <p>
        ProxiTravaux est une plateforme de mise en relation entre artisans et particuliers. Les services incluent, mais
        ne sont pas limités à :
      </p>
      <ul>
        <li>La consultation de profils d&apos;artisans.</li>
        <li>
          La visualisation des informations publiques des artisans, telles que leurs compétences, expériences et
          réalisations.
        </li>
      </ul>

      <h3>Article 4 – Accès</h3>
      <h4>4.1. Accès à la plateforme</h4>
      <ul>
        <li>
          L&apos;accès à la plateforme est gratuit et ne nécessite aucune inscription de la part des utilisateurs.
        </li>
        <li>
          ProxiTravaux se réserve le droit de suspendre ou limiter l&apos;accès pour des raisons techniques ou de
          maintenance.
        </li>
      </ul>

      <h4>4.2. Limitation de responsabilité</h4>
      <p>ProxiTravaux n&apos;offre aucune garantie quant à la disponibilité continue du service.</p>

      <h3>Article 5 – Engagements des utilisateurs</h3>
      <h4>5.1. Utilisation de la plateforme</h4>
      <ul>
        <li>
          Les utilisateurs s&apos;engagent à utiliser la plateforme uniquement à des fins de consultation des
          informations disponibles.
        </li>
        <li>
          Toute tentative de collecte massive ou automatisée des données présentes sur la plateforme est interdite.
        </li>
      </ul>

      <h4>5.2. Comportement général</h4>
      <ul>
        <li>
          Les utilisateurs s&apos;engagent à respecter les droits des artisans et à ne pas diffuser des commentaires ou
          propos diffamatoires à leur encontre.
        </li>
      </ul>

      <h3>Article 6 – Propriété intellectuelle</h3>
      <p>
        Le contenu de la plateforme, incluant textes, images, logos, et outils, est protégé par les lois sur la
        propriété intellectuelle. Toute reproduction, distribution ou utilisation non autorisée est interdite.
      </p>

      <h3>Article 7 – Données personnelles</h3>
      <p>
        ProxiTravaux ne collecte pas de données personnelles auprès des utilisateurs qui consultent la plateforme. Les
        seules informations présentes sont celles fournies volontairement par les artisans pour leurs profils
        professionnels.
      </p>

      <h3>Article 8 – Responsabilité</h3>
      <ul>
        <li>
          ProxiTravaux agit uniquement en tant que facilitateur et n&apos;est pas responsable des prestations réalisées
          par les artisans.
        </li>
        <li>
          ProxiTravaux ne peut être tenu responsable des informations fournies par les artisans sur leurs profils.
        </li>
      </ul>

      <h3>Article 9 – Réclamations et litiges</h3>
      <p>
        En cas de litige concernant les informations publiées sur la plateforme, les utilisateurs peuvent contacter
        ProxiTravaux <Link to="/contact">via ce formulaire</Link>. Toutefois, ProxiTravaux n&apos;intervient pas dans
        les relations contractuelles ou les litiges entre artisans et leurs clients.
      </p>

      <h3>Article 10 – Modification des CGU</h3>
      <p>
        ProxiTravaux se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs seront informés
        des modifications via la plateforme.
      </p>

      <h3>Article 11 – Loi applicable et juridiction</h3>
      <p>
        Les présentes CGU sont soumises au droit français. En cas de litige, les tribunaux compétents seront ceux du
        ressort de Paris.
      </p>

      <p>
        Pour toute question relative à ces CGU, veuillez nous contacter <Link to="/contact">ici</Link>.
      </p>
    </>
  );
});

TermsOfUse.displayName = 'TermsOfUse';

export default TermsOfUse;
