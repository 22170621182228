import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login, logout, validateToken } from '../services/Api';

// Action pour valider le token
export const checkToken = createAsyncThunk('auth/checkToken', async (_, { rejectWithValue }) => {
  const response = await validateToken();
  if (!response || !response.token) return rejectWithValue('Token invalide');
  return response;
});

// Action pour se connecter
export const userLogin = createAsyncThunk('auth/login', async (user, { rejectWithValue }) => {
  const response = await login(user);
  if (!response) return rejectWithValue('Connexion échouée');
  return response;
});

// Action pour se déconnecter
export const userLogout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  const response = await logout();
  if (!response) return rejectWithValue('Déconnexion échouée');
  return response;
});

// Charger les données depuis localStorage
const savedAuth = JSON.parse(localStorage.getItem('auth')) || { userClaims: null, isLoading: false, error: null };

const authSlice = createSlice({
  name: 'auth',
  initialState: savedAuth, // Utiliser les données persistées comme état initial
  reducers: {},
  extraReducers: builder => {
    builder
      // Login
      .addCase(userLogin.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userClaims = action.payload;
        localStorage.setItem('auth', JSON.stringify(state)); // Sauvegarde après connexion
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Logout
      .addCase(userLogout.fulfilled, state => {
        state.userClaims = null;
        localStorage.removeItem('auth'); // Supprimer les données après déconnexion
      })
      // Validate Token
      .addCase(checkToken.fulfilled, (state, action) => {
        state.userClaims = action.payload;
        localStorage.setItem('auth', JSON.stringify(state)); // Mettre à jour après validation
      })
      .addCase(checkToken.rejected, state => {
        state.userClaims = null;
        localStorage.removeItem('auth'); // Supprimer en cas d'échec de validation
      });
  }
});

export default authSlice.reducer;
