import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './InfosEtAides.module.css';

const InfosEtAides = memo(() => {
  const [activeIndex, setActiveIndex] = useState(null);

  const sections = [
    {
      title: 'Le Diagnostic de Performance Énergétique (DPE)',
      content: (
        <p className={styles.text}>
          Le DPE est un document obligatoire pour toute vente ou location d&apos;un bien immobilier. Il permet
          d&apos;évaluer la consommation énergétique et l&apos;empreinte écologique d&apos;un logement. En savoir plus
          sur le site officiel :{' '}
          <a href="https://www.service-public.fr" target="_blank" rel="noopener noreferrer">
            Service Public - DPE
          </a>
          <br></br>
          <Link to="/infos-dpe" className={styles.enSavoirPlusLink}>
            En savoir plus
          </Link>
        </p>
      )
    },
    {
      title: 'Comparatif des régimes fiscaux',
      content: (
        <p className={styles.text}>
          Découvrez les différents régimes fiscaux disponibles et comparez leurs avantages pour choisir celui qui
          correspond le mieux à vos besoins. Ce comparatif vous aidera à mieux comprendre les options fiscales qui
          s&apos;offrent à vous.
          <br />
          <Link to="/comparatif-regimes" className={styles.enSavoirPlusLink}>
            En savoir plus
          </Link>
        </p>
      )
    },
    {
      title: 'Le Loueur en Meublé Non Professionnel (LMNP)',
      content: (
        <>
          <p className={styles.text}>
            Le statut LMNP permet de bénéficier d&apos;une fiscalité avantageuse pour les biens loués meublés. Découvrez
            les conditions sur{' '}
            <a href="https://www.impots.gouv.fr" target="_blank" rel="noopener noreferrer">
              impots.gouv.fr
            </a>
            .
          </p>
          <p>
            <Link to="/infos-lmnp" className={styles.enSavoirPlusLink}>
              En savoir plus
            </Link>
          </p>
        </>
      )
    },

    {
      title: 'La Société Civile Immobilière (SCI)',
      content: (
        <p className={styles.text}>
          La SCI est un excellent moyen de gérer un patrimoine immobilier à plusieurs. Elle offre des avantages en
          termes de gestion et de transmission. <br></br>
          <Link to="/fisc-immo" className={styles.enSavoirPlusLink}>
            En savoir plus
          </Link>
        </p>
      )
    },
    {
      title: 'Location nue ou meublée',
      content: (
        <p className={styles.text}>
          La location nue offre une fiscalité simplifiée, tandis que la location meublée permet de générer des
          rendements plus élevés avec des avantages fiscaux (LMNP, LMP). Informez-vous selon vos besoins.<br></br>
          <Link to="/infos-location-nue" className={styles.enSavoirPlusLink}>
            En savoir plus
          </Link>
        </p>
      )
    },
    {
      title: 'Le Loueur en Meublé Professionnel (LMP)',
      content: (
        <p className={styles.text}>
          Découvrez le statut de Loueur en Meublé Professionnel (LMP), un choix fiscal avantageux pour les investisseurs
          immobiliers. Ce statut vous permet de bénéficier d&apos;avantages fiscaux, sous certaines conditions.
          <br />
          <Link to="/infos-lmp" className={styles.enSavoirPlusLink}>
            En savoir plus
          </Link>
        </p>
      )
    },
    {
      title: 'Le financement immobilier',
      content: (
        <p className={styles.text}>
          Explorez les différentes options de financement pour votre projet immobilier, y compris les prêts, les
          subventions et les avantages fiscaux. Vous trouverez des conseils pratiques pour optimiser votre
          investissement.
          <br />
          <Link to="/financement-immobilier" className={styles.enSavoirPlusLink}>
            En savoir plus
          </Link>
        </p>
      )
    },
    {
      title: "Stratégies d'investissement immobilier",
      content: (
        <p className={styles.text}>
          Découvrez les meilleures stratégies d&apos;investissement immobilier, que ce soit pour la location, la
          revente, ou les investissements à long terme. Adoptez une approche stratégique pour maximiser vos rendements.
          <br />
          <Link to="/strategie-investissement" className={styles.enSavoirPlusLink}>
            En savoir plus
          </Link>
        </p>
      )
    },
    {
      title: 'FAQ',
      content: (
        <p className={styles.text}>
          Retrouvez les réponses aux questions fréquentes sur la gestion des travaux, les aides financières, et bien
          plus encore.<br></br>
          <Link to="/faq" className={styles.enSavoirPlusLink}>
            Consultez notre FAQ ici
          </Link>
        </p>
      )
    }
  ];

  const toggleAccordion = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.infosEtAides}>
      <h1 className={styles.titrePrincipal}>Infos et aides</h1>
      <h2 className={styles.subtitle}>Retrouvez toutes les informations utiles pour vos projets immobiliers.</h2>
      <div className={styles.accordion}>
        {sections.map((section, index) => (
          <div key={index} className={`${styles.accordionItem} ${activeIndex === index ? styles.active : ''}`}>
            <button className={styles.accordionTitle} onClick={() => toggleAccordion(index)}>
              {section.title}
            </button>
            {activeIndex === index && <div className={styles.accordionContent}>{section.content}</div>}
          </div>
        ))}
      </div>
    </div>
  );
});

InfosEtAides.displayName = 'InfosEtAides';

export default InfosEtAides;
