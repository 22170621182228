import React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import FinancementImmobilier from '../components/InfoDocuments/FinancementImmobilier';

function FinancementImmobilierPage() {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb
          items={[
            { label: 'Infos et aides', path: '/infos-et-aides' },
            { label: 'Financement immobilier', path: '' }
          ]}
        />
        <FinancementImmobilier />
      </div>
      <Footer />
    </div>
  );
}

export default FinancementImmobilierPage;
