import React from 'react';
import { useParams } from 'react-router-dom';
import ArtisanDetailAdmin from '../components/ArtisanDetail/ArtisanDetailAdmin';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';

const ArtisanDetailAdminPage = () => {
  const { artisanId } = useParams();

  return (
    <div>
      <Header />
      <div className="page-content">
        {/* Fil d'Ariane géré à l'intérieur de la page car nécessite le nom de l'entreprise */}
        <ArtisanDetailAdmin artisanId={artisanId} />
      </div>
      <Footer />
    </div>
  );
};

export default ArtisanDetailAdminPage;
