import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import ResetPassword from '../components/Login/ResetPassword';

const ResetPasswordPage = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <ResetPassword />
      </div>
      <Footer />
    </div>
  );
};

export default ResetPasswordPage;
