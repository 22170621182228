import React, { memo } from 'react';
import styles from './InfoDocuments.module.css';

const LMPInfo = memo(() => {
  return (
    <div className={styles.InfoAideContainer}>
      <h1>La Location Meublée Professionnelle (LMP)</h1>
      <p>
        Le LMP (Loueur Meublé Professionnel) est un statut fiscal accordé aux propriétaires qui louent des biens
        immobiliers meublés et remplissent des critères spécifiques. Contrairement au LMNP (Loueur Meublé Non
        Professionnel), le LMP est considéré comme une activité professionnelle, ce qui implique des obligations et des
        avantages fiscaux particuliers. Voici les points essentiels pour comprendre le LMP :
      </p>
      <h2>Qu&apos;est-ce que le LMP ?</h2>
      <p>
        Pour être considéré comme Loueur Meublé Professionnel, deux conditions doivent être remplies simultanément : Les
        revenus tirés de l&apos;activité de location meublée doivent dépasser 23 000 € par an et ces revenus locatifs doivent
        représenter plus de 50 % des revenus professionnels du foyer fiscal (salaires, pensions, autres revenus
        d&apos;activités). Lorsque l&apos;on passe au statut de Loueur Meublé Professionnel (LMP), certaines obligations doivent
        être respectées dont l’inscription au Registre du Commerce et des Sociétés (RCS) ainsi qu’une tenue d’une
        comptabilité rigoureuse.
      </p>
      <p>
        Le LMP offre des avantages fiscaux intéressants, notamment grâce à deux régimes fiscaux : le régime micro-BIC et
        le régime réel. On est proche du LMNP avec quelques spécificités supplémentaires.
      </p>
      <h2>Le Micro-BIC et le Régime réel</h2>
      <p>
        En LMP (Location Meublée Professionnelle), il existe deux régimes fiscaux pour déclarer vos revenus locatifs :
        le régime Micro-BIC et le régime Réel.
      </p>
      <h3>Le régime Micro-BIC</h3>
      <p>
        Le régime Micro-BIC s’applique si vos revenus locatifs bruts sont inférieurs à 77 700 € par an. Avec ce régime,
        vous bénéficiez d’un abattement forfaitaire de 50 % sur vos revenus locatifs pour couvrir les charges, sans
        avoir besoin de détailler ces dernières. Cela simplifie la déclaration fiscale, car vous n’avez pas à justifier
        vos dépenses.
      </p>
      <h3>Le régime Réel</h3>
      <p>
        Le second régime est le régime Réel, il s’impose si vos revenus locatifs bruts dépassent 77 700 € par an, mais
        il est également possible d’opter pour ce régime en dessous de ce seuil. Le principal avantage du régime réel
        est la possibilité de déduire les charges réelles, telles que les intérêts d’emprunt, les travaux, les
        assurances, les frais de gestion et autres dépenses liées au bien.
      </p>
      <p>
        De plus, ce régime permet d’amortir sur plusieurs années la valeur du bien immobilier, le mobilier, les travaux
        et les frais d’acquisition, ce qui peut réduire significativement votre revenu imposable. Cependant, il
        nécessite une tenue de comptabilité détaillée et la déclaration précise des charges réelles.
      </p>
      <p>
        Le choix entre les deux régimes fiscaux, micro-BIC et régime réel, dépend avant tout de vos charges et de la
        complexité que vous êtes prêt à gérer. En effet, le régime réel peut être avantageux si vous avez des charges
        élevées ou si vous souhaitez bénéficier d’un amortissement pour réduire significativement votre revenu
        imposable.
      </p>
      <h2>Les catégories d’amortissement</h2>
      <ol>
        <li>
          <h3>Le bien immobilier :</h3>
          <p>
            La valeur amortissable concerne uniquement le bâti, excluant la valeur du terrain, car celui-ci n’est pas
            considéré comme dépréciable (il ne se dégrade pas dans le temps). Généralement, la valeur du bâti représente
            80 à 90 % du prix total d’acquisition du bien. Cet amortissement s’étale sur une durée de 20 à 30 ans, en
            fonction des pratiques comptables et de l’usure estimée.
          </p>
        </li>
        <li>
          <h3>Les frais d’acquisition :</h3>
          <p>
            Les frais liés à l’achat du bien, comme les frais de notaire ou d’agence, sont amortis sur la même durée que
            celle du bâti, soit 20 à 30 ans.
          </p>
        </li>
        <li>
          <h3>Le mobilier :</h3>
          <p>
            Les éléments meublant le bien, tels que les équipements électroménagers ou le mobilier, sont amortis sur une
            période plus courte, généralement entre 5 et 10 ans, selon leur nature.
          </p>
        </li>
        <li>
          <h3>Les travaux :</h3>
          <p>Les travaux réalisés sur le bien sont amortis sur des durées variables, en fonction de leur nature :</p>
          <ul>
            <li>
              <strong>Travaux lourds :</strong> Les rénovations structurelles ou de reconstruction, comme la rénovation
              de la toiture, la refonte des installations électriques, ou des modifications majeures dans l’agencement
              des pièces, sont amortis sur 20 à 30 ans.
            </li>
            <li>
              <strong>Travaux légers :</strong> Les travaux d’amélioration, comme les aménagements intérieurs,
              l’installation de systèmes de chauffage, ou l’isolation, bénéficient d’un amortissement plus court,
              souvent entre 5 et 10 ans.
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Ce système permet de répartir les dépenses importantes sur plusieurs années, diminuant chaque année le montant
        des revenus imposables.
      </p>

      <p>
        En régime réel, de nombreuses charges peuvent être déduites des revenus locatifs, ce qui permet de réduire la
        base imposable. Voici les principales charges déductibles :
      </p>

      <h3>1. Frais de gestion et d’administration</h3>
      <p>
        Cela inclut les honoraires des agences immobilières pour la gestion locative, les frais de syndic (pour les
        biens en copropriété), et les autres dépenses administratives liées à la gestion du bien.
      </p>

      <h3>2. Intérêts d’emprunt</h3>
      <p>
        Les intérêts des prêts contractés pour financer l’acquisition du bien ou réaliser des travaux sont déductibles.
        Ce poste peut représenter une part importante des charges, notamment au début du remboursement.
      </p>

      <h3>3. Assurances</h3>
      <p>
        Les primes d’assurance liées au bien, telles que l’assurance propriétaire non occupant (PNO) ou les garanties
        contre les loyers impayés, sont également déductibles.
      </p>

      <h3>4. Taxes foncières</h3>
      <p>
        Le montant de la taxe foncière sur les propriétés bâties peut être intégralement déduit des revenus locatifs,
        sauf pour la part relative à la taxe d’enlèvement des ordures ménagères, qui est généralement récupérable auprès
        du locataire.
      </p>

      <p>
        Ces déductions permettent de diminuer considérablement le revenu imposable, ce qui peut rendre le régime réel
        particulièrement avantageux pour les propriétaires ayant des charges élevées.
      </p>

      <h3>Exemple :</h3>
      <p>
        Prenons l&apos;exemple d&apos;un bien immobilier acheté 400 000 € avec 100 000 € de travaux, loué pour 2 500 € par mois,
        soit 30 000 € de revenus fonciers annuels. Nous allons comparer le régime micro-BIC et le régime réel en LMP.
      </p>

      <h4>1. Régime Micro-BIC</h4>
      <ul>
        <li>Revenus locatifs bruts : 30 000 €</li>
        <li>Abattement forfaitaire (50 %) : 30 000 € × 50 % = 15 000 €</li>
        <li>Revenus imposables : 30 000 € - 15 000 € = 15 000 €</li>
      </ul>
      <p>
        Ces 15 000 € sont soumis à l’impôt sur le revenu, selon votre tranche marginale d’imposition (TMI), et aux
        cotisations sociales (environ 35%). Le Micro-BIC est simple à gérer, mais ne tient pas compte des charges
        réelles ni de l’amortissement.
      </p>

      <h4>2. Régime Réel</h4>
      <p>
        Le régime réel permet de déduire les charges réelles et d’amortir le bien et les travaux, ce qui réduit
        fortement les revenus imposables.
      </p>
      <h5>Calcul des amortissements :</h5>
      <ul>
        <li>Amortissement du bien (hors terrain) :</li>
        <ul>
          <li>Terrain estimé à 10 % du prix total, soit une base amortissable de 360 000 €.</li>
          <li>Amortissement annuel : 360 000 € ÷ 30 = 12 000 €</li>
        </ul>
        <li>Amortissement des travaux :</li>
        <ul>
          <li>Travaux amortis sur 10 ans.</li>
          <li>Amortissement annuel : 100 000 € ÷ 10 = 10 000 €</li>
        </ul>
      </ul>
      <h4>Charges annuelles déductibles :</h4>
      <ul>
        <li>Intérêts d’emprunt : 3 000 €</li>
        <li>Assurance, taxe foncière, autres frais : 3 000 €</li>
        <li>Amortissements : 12 000 € (bien) + 10 000 € (travaux) = 22 000 €</li>
      </ul>
      <p>Revenus nets imposables :</p>
      <ul>
        <li>Revenus locatifs : 30 000 €</li>
        <li>
          Total des charges déductibles : 3 000 € (intérêts) + 3 000 € (frais) + 22 000 € (amortissements) = 28 000 €
        </li>
        <li>Revenus imposables : 30 000 € - 28 000 € = 2 000 €</li>
      </ul>

      <div className={styles.tableContainer}>
        <h3>Comparaison :</h3>
        <table>
          <thead>
            <tr>
              <th>Micro-BIC</th>
              <th>Régime Réel</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Revenus locatifs bruts : 30 000 €</td>
              <td>Revenus locatifs bruts : 30 000 €</td>
            </tr>
            <tr>
              <td>Abattement / Charges déductibles : 15 000 €</td>
              <td>Charges déductibles : 28 000 €</td>
            </tr>
            <tr>
              <td>Revenus imposables : 15 000 €</td>
              <td>Revenus imposables : 2 000 €</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p>
        Dans cet exemple, le régime réel est beaucoup plus avantageux puisqu’il permet de ramener le revenu imposable à
        seulement 2 000 € grâce à la prise en compte des charges réelles et des amortissements, contre 15 000 € avec le
        micro-BIC.
      </p>

      <p>
        Le micro-BIC est plus simple à gérer administrativement et il peut être intéressant si vos charges déductibles
        et vos amortissements sont faibles.
      </p>

      <h2>La Plus-Value Immobilière</h2>
      <p>
        En Loueur Meublé Professionnel (LMP), la fiscalité des plus-values immobilières suit les règles des plus-values
        professionnelles, ce qui la distingue des régimes comme le LMNP ou la location nue.
      </p>
      <p>
        Lors de la revente d’un bien, la plus-value est calculée comme la différence entre le prix de vente et la valeur
        nette comptable du bien. Cette dernière correspond au prix d&apos;achat du bien diminué des amortissements pratiqués
        pendant la période de détention.
      </p>
      <h3>La plus-value est ensuite divisée en deux parties pour son imposition :</h3>
      <h4>Plus-value à court terme (CT)</h4>
      <p>
        Elle correspond à la part des amortissements pratiqués sur le bien pendant la période de détention. La
        plus-value à court terme est imposée comme un revenu ordinaire, au barème progressif de l’impôt sur le revenu
        (selon votre tranche marginale) et est également soumise aux cotisations sociales (environ 35 %).
      </p>
      <h4>Plus-value à long terme (LT)</h4>
      <p>
        Elle correspond au reste de la plus-value, c’est-à-dire la différence entre le prix de vente et la valeur nette
        comptable du bien, après déduction des amortissements. En d’autres termes, c’est la partie de la plus-value qui
        dépasse la réintégration des amortissements. La plus-value à long terme bénéficie d’une imposition spécifique à
        19 %, avec des prélèvements sociaux de 17,2 %, soit une imposition totale de 36,2 %.
      </p>
      <p>
        Contrairement à d&apos;autres régimes comme le LMNP, il n’y a pas d’abattement pour durée de détention en LMP. Cela
        signifie que la totalité de la plus-value, qu’elle soit à court terme ou à long terme, est imposable.
      </p>
      <p>
        Cependant, une exonération totale ou partielle est possible si les recettes locatives annuelles sont inférieures
        à 90 000 € et si l&apos;activité a été exercée pendant au moins 5 ans.
      </p>
      <p>Pour des recettes comprises entre 90 000 € et 126 000 €, une exonération partielle peut être appliquée.</p>
      <h3>Exemple :</h3>
      <p>
        Prenons l’exemple ci-dessus du bien acheté 400 000 euros avec 100 000 euros de travaux et revendu 600 000 euros
        dans 4 ans.
      </p>
      <p>
        L&apos;amortissement réduit la valeur fiscale du bien. Ainsi, la base imposable pour le calcul de la plus-value est
        calculée sur la valeur après amortissement. L&apos;amortissement a été effectué sur 30 ans, avec une base de 90 % du
        prix du bien, soit 360 000 €. Les travaux ont été amortis sur 10 ans.
      </p>
      <h4>Calcul des Amortissements :</h4>
      <ul>
        <li>
          Amortissement du bien immobilier : 360 000 € (sur 30 ans). Cela signifie qu&apos;après 4 ans d&apos;amortissement, la
          valeur nette fiscale du bien est de : 400 000 € - 48 000 € = 352 000 €.
        </li>
        <li>
          Amortissement des travaux : 100 000 € (sur 10 ans), donc après 4 ans, 40 000 euros ont été amortis. La base
          fiscale des travaux est donc = 60 000 €.
        </li>
      </ul>
      <h4>Calcul de la plus-value :</h4>
      <h5>1. Plus-value brute :</h5>
      <p>
        Le prix de vente du bien est de 600 000 €. La valeur nette fiscale du bien, après amortissement, est de 352 000
        € pour le bien immobilier et de 60 000 € pour les travaux, ce qui donne une valeur nette fiscale totale de 412
        000 €. Ainsi, la plus-value brute est calculée en soustrayant la valeur nette fiscale du prix de vente, soit 600
        000 € - 412 000 €, ce qui donne une plus-value de 188 000 €.
      </p>
      <h5>2. Répartition entre plus-value à court terme et à long terme :</h5>
      <p>
        Plus-value à court terme : Elle correspond aux amortissements déduits pendant la période de détention soit 88
        000 €.
      </p>
      <p>
        Plus-value à long terme : Elle correspond à la différence entre la plus-value brute et les amortissements
        déduits : 188 000 € - 88 000 € = 100 000 €.
      </p>
      <h4>Imposition de la plus-value :</h4>
      <h5>a) Imposition de la plus-value à court terme (amortissements) :</h5>
      <p>Amortissements = 88 000 €</p>
      <p>Votre TMI : exemple à 30%</p>
      <ul>
        <li>Impôt sur le revenu : 88 000 € × 30 % = 26 400 €</li>
        <li>Cotisations sociales : 88 000 € × 35 % = 30 800 €</li>
        <li>Total court terme : 26 400 € + 30 800 € = 57 200 €</li>
      </ul>
      <h5>b) Imposition de la plus-value à long terme :</h5>
      <p>Plus-value à long terme = 100 000 €</p>
      <ul>
        <li>Imposition à 36,2 % : Impôt : 100 000 € × 36,2 % = 36 200 €</li>
      </ul>
      <h4>Total de l&apos;imposition sur la plus-value :</h4>
      <ul>
        <li>CT : 57 200 €</li>
        <li>LT : 36 200 €</li>
        <li>Total : 93 400 €</li>
      </ul>
      <p>
        La fiscalité sur la plus-value en LMP peut être lourde en raison de la réintégration des amortissements dans la
        plus-value à court terme. Il est essentiel de bien planifier une revente pour minimiser ces impacts fiscaux.
      </p>

      <h2>Conclusion</h2>
      <p>
        Le statut de Loueur Meublé Professionnel (LMP) offre des avantages significatifs, notamment la possibilité
        d’amortir le bien et les charges, de déduire les déficits sur le revenu global sans limite, et de bénéficier
        d’une éventuelle exonération de la plus-value sous certaines conditions.
      </p>
      <p>
        Ces avantages le rendent particulièrement adapté aux investisseurs générant des revenus locatifs élevés ou
        cherchant à professionnaliser leur activité.
      </p>
      <p>
        Cependant, il comporte des inconvénients, tels que des obligations administratives plus lourdes (inscription au
        RCS, tenue d’une comptabilité stricte) et une fiscalité potentiellement importante lors de la revente, notamment
        en raison de la réintégration des amortissements dans la plus-value à court terme.
      </p>
      <p>
        En résumé, le LMP est avantageux pour les investisseurs disposés à gérer une gestion complexe et souhaitant
        maximiser leur rentabilité sur le long terme.
      </p>
    </div>
  );
});

LMPInfo.displayName = 'LMPInfo';
export default LMPInfo;
