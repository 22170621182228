import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import ForgetPassword from '../components/Login/ForgetPassword';

function ForgetPasswordPage() {
  return (
    <div>
      <Header />
      <div className="page-content">
        <ForgetPassword />
      </div>
      <Footer />
    </div>
  );
}

export default ForgetPasswordPage;
