import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styles from './QualifiedArtisansSection.module.css';

const features = [
  {
    title: 'Les artisans dont vous avez besoin',
    description:
      'Notre plateforme sélectionne des professionnels expérimentés pour répondre à vos attentes avec expertise.'
  },
  {
    title: 'De véritables avis clients',
    description: 'Des retours authentiques pour vous assurer de la qualité et de la fiabilité des services proposés.'
  },
  {
    title: 'Vous avez le contrôle',
    description: 'Comparez les devis et choisissez les artisans qui répondent le mieux à vos besoins et à votre budget.'
  }
];

const Feature = ({ title, description }) => (
  <div className={styles.feature}>
    <h3 className={styles.featureTitle}>{title}</h3>
    <p>{description}</p>
  </div>
);

const QualifiedArtisansSection = memo(() => {
  return (
    <section className={styles.qualifiedArtisansSection}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>Pourquoi ProxiTravaux est la solution fiable</h2>
        <p className={styles.description}>
          Il n&apos;est pas toujours facile de faire appel à un artisan quand on en a besoin. ProxiTravaux est là pour
          vous aider à engager facilement des experts pour tous vos travaux.
        </p>
        <div className={styles.features}>
          {features.map((feature, index) => (
            <Feature key={index} title={feature.title} description={feature.description} />
          ))}
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src="assets/proxitravaux-solutions.webp" alt="Artisans" className={styles.artisanImage} loading="lazy" />
      </div>
    </section>
  );
});

QualifiedArtisansSection.displayName = 'QualifiedArtisansSection';

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default QualifiedArtisansSection;
