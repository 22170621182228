import PropTypes from 'prop-types';
import React, { memo } from 'react';
import styles from './ArtisansCard.module.css';

const artisans = [
  { src: 'assets/plombier-fixation-tuyaux.webp', alt: 'Artisan 1', metier: 'PLOMBIER' },
  { src: 'assets/menuisier-atelier-bois.webp', alt: 'Artisan 2', metier: 'MENUISIER' },
  { src: 'assets/charpentier-construction-bois.webp', alt: 'Artisan 3', metier: 'CHARPENTIER' },
  { src: 'assets/electricien-tableau.webp', alt: 'Artisan 4', metier: 'ELECTRICIEN' }
];

const ArtisanItem = ({ src, alt, metier }) => (
  <div className={styles.artisanImage}>
    <img src={src} alt={alt} loading="lazy" />
    <div className={styles.metierText}>{metier}</div>
  </div>
);

const ArtisansCard = memo(() => {
  return (
    <section className={styles.artisansCard}>
      <h2 className={styles.title}>FAITES CONFIANCE À DES ARTISANS VÉRIFIÉS ET QUALIFIÉS</h2>
      <p className={styles.subtitle}>
        Nous sélectionnons les meilleurs artisans de votre région pour vous garantir un service de qualité.
      </p>
      <div className={styles.artisanGallery}>
        {artisans.map((artisan, index) => (
          <ArtisanItem key={index} {...artisan} />
        ))}
      </div>
    </section>
  );
});

ArtisansCard.displayName = 'ArtisanCard';

ArtisanItem.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  metier: PropTypes.string.isRequired
};

export default ArtisansCard;
