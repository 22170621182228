import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styles from './NosPartenaires.module.css';

const NosPartenaires = memo(() => {
  const partners = [
    {
      category: 'Gros œuvre',
      items: [
        {
          name: 'Partenaire A',
          description: 'Fournisseur de matériaux de construction offrant une réduction de 5% sur vos achats.',
          logo: '/placeholder-logo.png',
          link: '/partenaire-a'
        },
        {
          name: 'Partenaire B',
          description: 'Spécialiste en béton armé avec des services rapides et efficaces.',
          logo: '/placeholder-logo.png',
          link: '/partenaire-b'
        }
      ]
    },
    {
      category: 'Outillage',
      items: [
        {
          name: 'Partenaire C',
          description: 'Distributeur d’outils professionnels à prix réduit pour nos artisans.',
          logo: '/placeholder-logo.png',
          link: '/partenaire-c'
        }
      ]
    },
    {
      category: 'Assurance',
      items: [
        {
          name: 'Partenaire D',
          description: 'Expert en assurance travaux avec des garanties personnalisées.',
          logo: '/placeholder-logo.png',
          link: '/partenaire-d'
        }
      ]
    }
  ];

  return (
    <div className={styles.nosPartenaires}>
      {/* Titre principal */}
      <header className={styles.header}>
        <h1 className={styles.title}>Nos Partenaires</h1>
        <p className={styles.subtitle}>
          Découvrez nos partenaires de confiance qui travaillent avec nous pour vous offrir les meilleurs services et
          avantages.
        </p>
      </header>

      {/* Contenu des partenaires */}
      <div className={styles.content}>
        {partners.map((category, index) => (
          <section key={index} className={styles.categorySection}>
            <h2 className={styles.categoryTitle}>{category.category}</h2>
            <div className={styles.cardGrid}>
              {category.items.map((partner, idx) => (
                <div key={idx} className={styles.partnerCard}>
                  <img src={partner.logo} alt={`${partner.name} logo`} className={styles.partnerLogo} loading="lazy" />
                  <h3 className={styles.partnerName}>{partner.name}</h3>
                  <p className={styles.partnerDescription}>{partner.description}</p>
                  <a href={partner.link} className={styles.partnerLink}>
                    En savoir plus
                  </a>
                </div>
              ))}
            </div>
          </section>
        ))}
      </div>

      {/* Appel à l'action */}
      <footer className={styles.cta}>
        <h2>Devenir partenaire</h2>
        <p>Rejoignez notre réseau et profitez des nombreux avantages de travailler avec nous.</p>
        <Link to="/devenir-partenaire" className={styles.ctaButton}>
          Nous rejoindre
        </Link>
      </footer>
    </div>
  );
});

NosPartenaires.displayName = 'NosPartenaires';

export default NosPartenaires;
