import React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import NosPartenaires from '../components/NosPartenaires/NosPartenaires';

const PartenairesPage = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb items={[{ label: 'Nos Partenaires', path: '' }]} />
        <NosPartenaires />
      </div>
      <Footer />
    </div>
  );
};

export default PartenairesPage;
