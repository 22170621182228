import React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Propos from '../components/Propos/Propos';

const ProposPage = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb items={[{ label: 'À propos', path: '' }]} />
        <Propos />
      </div>
      <Footer />
    </div>
  );
};

export default ProposPage;
