import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import DetailArtisan from './components/Backoffice/DetailArtisan';
import HomeBackoffice from './components/Backoffice/HomeBackoffice';
import ListAccount from './components/Backoffice/ListAccount';
import ListArtisan from './components/Backoffice/ListArtisan';
import ListParticulier from './components/Backoffice/ListParticulier';
import ListSpecialite from './components/Backoffice/ListSpecialite';
import ArtisanDetailAdminPage from './pages/ArtisanDetailAdminPage';
import ArtisanDetailPage from './pages/ArtisanDetailPage';
import ArtisansPage from './pages/ArtisansPage';
import CGUPage from './pages/CGUPage';
import ComparatifRegimesPage from './pages/ComparatifRegimesPage';
import ConnexionPage from './pages/ConnexionPage';
import ContactPage from './pages/ContactPage';
import DpePage from './pages/DpePage';
import FaqPage from './pages/FaqPage';
import FinancementImmobilierPage from './pages/FinancementImmobilierPage';
import FiscaliteImmobilierePage from './pages/FiscaliteImmobilierePage';
import ForgetPasswordPage from './pages/ForgetPasswordPage';
import HomePage from './pages/HomePage';
import InfoLmnpPage from './pages/InfoLmnpPage';
import InfosEtAidesPage from './pages/InfosEtAidesPage';
import InvestmentStrategiesPage from './pages/InvestmentStrategiesPage';
import JoinPage from './pages/JoinPage';
import LMPInfoPage from './pages/LMPInfoPage';
import LocationNuePage from './pages/LocationNuePage';
import MapPage from './pages/MapPage';
import MetierPage from './pages/MetierPage';
import NotFound from './pages/NotFound';
import PartenairesPage from './pages/PartenairesPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import PrivatePages from './pages/PrivatePages';
import ProposPage from './pages/ProposPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import store from './redux/store';
import './styles/global.css'; // ou le nom de ton fichier CSS principal

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => (
  <>
    <ToastContainer
      position="top-center" // Change la position pour être plus centrale et plus mobile-friendly
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      pauseOnHover
      draggable
      pauseOnFocusLoss
      theme="light"
      style={{
        // Rendre les notifications plus discrètes sur les petits écrans
        maxWidth: '95vw',
        margin: '10px',
        fontSize: '1rem'
      }}
    />
    <Router
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true
      }}
    >
      <ScrollToTop />
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<ProposPage />} />
          <Route path="/metier/:specialiteId" element={<MetierPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/metiers" element={<ArtisansPage />} />
          <Route path="/map" element={<MapPage />} />
          <Route path="/join" element={<JoinPage />} />
          <Route path="/artisan/:artisanId" element={<ArtisanDetailPage />} />

          <Route path="/infos-et-aides" element={<InfosEtAidesPage />} />
          <Route path="/infos-dpe" element={<DpePage />} />
          <Route path="/infos-location-nue" element={<LocationNuePage />} />
          <Route path="/fisc-immo" element={<FiscaliteImmobilierePage />} />
          <Route path="/comparatif-regimes" element={<ComparatifRegimesPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/cgu" element={<CGUPage />} />
          <Route path="/politique-confidentialite" element={<PrivacyPolicyPage />} />
          <Route path="/infos-lmnp" element={<InfoLmnpPage />} />
          <Route path="/financement-immobilier" element={<FinancementImmobilierPage />} />
          <Route path="/strategie-investissement" element={<InvestmentStrategiesPage />} />
          <Route path="/infos-lmp" element={<LMPInfoPage />} />

          <Route path="/forgot-password" element={<ForgetPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/admin" element={<ConnexionPage />} />
          <Route path="/admin/*" element={<PrivatePages />}>
            <Route path="artisan/:artisanId" element={<ArtisanDetailAdminPage />} />
          </Route>
          <Route path="/partenaires" element={<PartenairesPage />} />

          <Route path="/backoffice" element={<ConnexionPage />} />
          <Route path="/backoffice/admin/*" element={<PrivatePages />}>
            <Route path="home" element={<HomeBackoffice />} />
            <Route path="artisans" element={<ListArtisan />} />
            <Route path="artisan/:artisanId" element={<DetailArtisan />} />
            <Route path="specialites" element={<ListSpecialite />} />
            <Route path="particuliers" element={<ListParticulier />} />
            <Route path="accounts" element={<ListAccount />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Provider>
    </Router>
  </>
);

export default App;
