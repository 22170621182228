import React, { memo } from 'react';
import styles from './InfoDocuments.module.css';

const Faq = memo(() => {
  return <div className={styles.InfoAideContainer}>Page en cours de développement.</div>;
});

Faq.displayName = 'Faq';

export default Faq;
