import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Breadcrumb.module.css';

const Breadcrumb = ({ items }) => {
  if (!items || items.length < 1 || items.length > 3) {
    console.error('Le composant Breadcrumb nécessite 1 ou 2 éléments dans le tableau `items`.');
    return null;
  }

  return (
    <div className={styles.breadcrumb}>
      <Link to="/" className={styles.breadcrumbLink}>
        Accueil
      </Link>
      {items.map((item, index) => (
        <React.Fragment key={item.path}>
          {' '}
          &gt;{' '}
          {index < items.length - 1 ? (
            <Link to={item.path} className={styles.breadcrumbLink}>
              {item.label}
            </Link>
          ) : (
            <span className={styles.breadcrumbCurrent}>{item.label}</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string
    })
  ).isRequired
};

export default Breadcrumb;
