import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import styles from './NotFound.module.css';

const NotFound = () => {
  return (
    <>
      <Breadcrumb items={[{ label: 'Page inexistante', path: '' }]} />

      <div className={styles.notFoundContent}>
        <div className="page-content">
          <h1>404 - Page non trouvée</h1>
          <p>Oups ! La page que vous cherchez n&apos;existe pas ou a été déplacée.</p>
          <img src="assets/404-illustration.webp" alt="Page non trouvée" className={styles.notFoundImage} />
          <Link to="/" className={styles.backHomeButton}>
            Retour à la page d&apos;accueil
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
