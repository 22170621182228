import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateUserPassword } from '../../services/Api';
import styles from './Login.module.css';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const timestamp = searchParams.get('timestamp');
  const hash = searchParams.get('hash');
  const navigate = useNavigate();

  const validatePassword = password => {
    const minLength = 8;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (password.length < minLength) {
      return 'Le mot de passe doit contenir au moins 8 caractères.';
    }
    if (!regex.test(password)) {
      return 'Le mot de passe doit inclure une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.';
    }
    return null;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const validationError = validatePassword(newPassword);
    if (validationError) {
      toast.error(validationError);
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      const response = await updateUserPassword(email, timestamp, hash, newPassword);
      toast.success(response.message || 'Mot de passe réinitialisé avec succès.');
      navigate('/');
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite lors de la réinitialisation du mot de passe.");
    }
  };

  return (
    <div className={styles.passwordResetContainer}>
      <h2>Réinitialisez votre mot de passe</h2>
      <p>Saisissez un nouveau mot de passe pour votre compte.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Nouveau mot de passe"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirmez le mot de passe"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit">Réinitialiser le mot de passe</button>
      </form>
    </div>
  );
};

export default ResetPassword;
