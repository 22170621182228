import React from 'react';

import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import FiscaliteImmobiliere from './../components/InfoDocuments/FiscaliteImmobiliere';

const FiscaliteImmobilierePage = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb
          items={[
            { label: 'Infos et aides', path: '/infos-et-aides' },
            { label: 'La fiscalité immobilière', path: '' }
          ]}
        />
        <FiscaliteImmobiliere />
      </div>
      <Footer />
    </div>
  );
};

export default FiscaliteImmobilierePage;
