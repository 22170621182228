import React, { memo } from 'react';

const InfosDpe = memo(() => {
  return (
    <div>
      <header>
        <h1>Le Diagnostic de Performance Énergétique (DPE)</h1>
      </header>

      <section>
        <h2>Qu&apos;est-ce que le Diagnostic de Performance Énergétique (DPE) ?</h2>
        <p>
          Le Diagnostic de Performance Énergétique (DPE) est un document essentiel dans la gestion et la transaction de
          biens immobiliers en France. C&apos;est un document obligatoire pour toute vente ou location d&apos;un bien
          immobilier en France. De plus, il est désormais opposable juridiquement, ce qui renforce son importance dans
          les transactions immobilières.
        </p>
        <p>
          Il informe les propriétaires et les locataires sur la consommation énergétique et l&apos;impact
          environnemental d&apos;un logement ou d&apos;un bâtiment.
        </p>

        <h2>Pourquoi le DPE est-il important ?</h2>
        <p>
          Le DPE est crucial dans le cadre de la gestion et de la valorisation des biens immobiliers pour plusieurs
          raisons importantes :
        </p>
        <ul>
          <li>Évaluation de la performance énergétique du logement</li>
          <li>
            Une bonne performance énergétique (classes A ou B) est un atout pour réduire les charges des occupants et
            valoriser le bien.
          </li>
          <li>
            Une mauvaise performance (classes F ou G) peut classer le logement parmi les passoires thermiques, ce qui
            entraîne des restrictions de location.
          </li>
        </ul>

        <h3>Les nouvelles lois imposent des interdictions de location progressive pour les logements énergivores :</h3>
        <ul>
          <li>2025 : Interdiction de louer les logements classés G.</li>
          <li>2028 : Interdiction pour les logements classés F.</li>
          <li>2034 : Interdiction pour les logements classés E.</li>
        </ul>

        <h2>Comment est réalisé le DPE ?</h2>
        <p>Le DPE est effectué par un diagnostiqueur certifié, qui analyse plusieurs éléments du logement :</p>
        <ul>
          <li>Isolation : État des murs, toitures, fenêtres.</li>
          <li>Système de Chauffage : Type et performance des équipements.</li>
          <li>Production d&apos;Eau Chaude : Efficacité des installations.</li>
          <li>Ventilation : Qualité du système de ventilation.</li>
        </ul>

        <p>
          Le DPE est valable pendant une durée de 10 ans. Son étiquette énergie doit obligatoirement être mentionnée
          dans les annonces immobilières pour les ventes et les locations.
        </p>

        <h2>Pourquoi et comment optimiser un DPE ?</h2>
        <p>Pour les Locataires :</p>
        <ul>
          <li>Un bon DPE signifie des factures énergétiques plus basses et un impact environnemental réduit.</li>
        </ul>

        <p>Pour les Propriétaires :</p>
        <ul>
          <li>Améliorer le DPE avant de vendre ou de louer peut augmenter l&apos;attractivité et la valeur du bien.</li>
        </ul>

        <p>Pour les Investisseurs :</p>
        <ul>
          <li>
            Les biens affichant une mauvaise performance énergétique peuvent représenter une opportunité d&apos;achat,
            car ils sont souvent proposés à des prix plus attractifs.
          </li>
        </ul>

        <h3>Pour rendre votre logement plus performant énergétiquement :</h3>
        <ul>
          <li>Isolation : Renforcer l&apos;isolation des murs et des toitures, remplacer les fenêtres anciennes.</li>
          <li>Chauffage et Eau Chaude : Installer une pompe à chaleur ou une chaudière à condensation.</li>
          <li>Énergies Renouvelables : Installer des panneaux solaires ou un ballon thermodynamique.</li>
          <li>Ventilation : Mettre en place une ventilation mécanique contrôlée (VMC) double flux.</li>
        </ul>

        <h2>Conclusion</h2>
        <p>
          Le DPE est un outil essentiel pour évaluer et améliorer la performance énergétique des logements. Une bonne
          compréhension de ses mécanismes et une utilisation judicieuse des informations qu&apos;il fournit peuvent
          aider à optimiser la consommation d&apos;énergie, réduire les factures et valoriser votre bien immobilier.
        </p>
      </section>
    </div>
  );
});

InfosDpe.displayName = 'InfosDpe';

export default InfosDpe;
