import { getDistance } from 'geolib';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getArtisansBySpecialiteId, getDetailArtisanByArtisanIdAndType, getSpecialityById } from '../../services/Api';
import SearchPlaceForMetier from '../SearchPlace/SearchPlaceForMetier';
import styles from './Metier.module.css';

const ArtisanCardInMetier = memo(({ artisan }) => (
  <li key={artisan.id} className={styles.artisanCard}>
    <Link to={`/artisan/${artisan.id}`}>
      <div className={styles.artisanImage}>
        <img
          src={`data:${artisan.image_principale?.mimeType};base64,${artisan.image_principale?.imageDetail}`}
          alt={artisan.image_principale?.imageDetail || 'Image indisponible'}
        />
      </div>
    </Link>
    <div className={styles.artisanInfo}>
      <p className={styles.artisanName}>
        <Link to={`/artisan/${artisan.id}`}>{artisan.entrepriseNom}</Link>
      </p>
      <p>Ville : {artisan.adresse?.ville}</p>
      <p>Rayon d&apos;activité : {artisan.rayonActivite} km</p>
    </div>
  </li>
));

ArtisanCardInMetier.displayName = 'ArtisanCardInMetier';
ArtisanCardInMetier.propTypes = {
  artisan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    entrepriseNom: PropTypes.string.isRequired,
    anneeExperience: PropTypes.string,
    siret: PropTypes.string.isRequired,
    siren: PropTypes.string.isRequired,
    adresse: PropTypes.shape({
      ville: PropTypes.string
    }),
    rayonActivite: PropTypes.string,
    image_principale: PropTypes.shape({
      mimeType: PropTypes.string,
      imageDetail: PropTypes.string
    })
  }).isRequired
};

const Metier = ({ specialiteId }) => {
  const [artisans, setArtisans] = useState([]);
  const [specialite, setSpecialite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tri, setTri] = useState('pertinence');
  const [userLocation, setUserLocation] = useState(null);
  const containerRef = useRef(null);
  const [openList, setOpenList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const cardsPerPage = 10; // Maximum cards per page

  useEffect(() => {
    const initializeData = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        });
      }

      try {
        const [artisansData, spe] = await Promise.all([
          getArtisansBySpecialiteId(specialiteId),
          getSpecialityById(specialiteId)
        ]);

        const artisansWithImages = await Promise.all(
          artisansData.map(async artisan => {
            const image_principale = await getDetailArtisanByArtisanIdAndType(artisan.id, 'IMG_PRINCIPALE');
            return { ...artisan, image_principale };
          })
        );

        setArtisans(artisansWithImages);
        setSpecialite(spe);
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, [specialiteId]);

  // Fermer les listes déroulantes si on clique en dehors
  const handleClickOutside = useCallback(event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpenList(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleSearchPlaceSelect = suggestion => {
    const [longitude, latitude] = suggestion.geometry.coordinates;
    setUserLocation({ latitude, longitude });
    setOpenList(false);
  };

  const trierArtisans = useMemo(() => {
    if (tri === 'localisation' && userLocation) {
      return [...artisans].sort((a, b) => {
        const distanceA = getDistance(
          { latitude: userLocation.latitude, longitude: userLocation.longitude },
          { latitude: a.latitude, longitude: a.longitude }
        );
        const distanceB = getDistance(
          { latitude: userLocation.latitude, longitude: userLocation.longitude },
          { latitude: b.latitude, longitude: b.longitude }
        );
        return distanceA - distanceB;
      });
    }
    return artisans;
  }, [tri, userLocation, artisans]);

  const currentArtisans = useMemo(() => {
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    return trierArtisans.slice(indexOfFirstCard, indexOfLastCard);
  }, [trierArtisans, currentPage, cardsPerPage]);

  const totalPages = Math.ceil(artisans.length / cardsPerPage);

  const handleNextPage = useCallback(() => {
    if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
  }, [currentPage, totalPages]);

  const handlePrevPage = useCallback(() => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  }, [currentPage]);

  if (loading) {
    return <p>Chargement des artisans...</p>;
  }

  if (artisans.length === 0) {
    return <p>Aucun artisan trouvé pour cette spécialité.</p>;
  }

  return (
    <div className={styles.metierContainer}>
      <div className={styles.breadcrumb}>
        <Link to="/" className={styles.breadcrumbLink}>
          Accueil
        </Link>{' '}
        &gt;{' '}
        <Link to="/metiers" className={styles.breadcrumbLink}>
          Métiers
        </Link>{' '}
        &gt; <span className={styles.breadcrumbCurrent}>{specialite?.metier}</span>
      </div>
      <h2 className={styles.title}>{specialite.metier}</h2>

      <div className={styles.triWrapper}>
        <div className={styles.triContainer}>
          <div className={styles.triSelectContainer}>
            <label htmlFor="tri">Trier par:</label>
            <select id="tri" value={tri} onChange={e => setTri(e.target.value)}>
              <option value="pertinence">Pertinence</option>
              <option value="localisation">Localisation</option>
            </select>
          </div>
          {tri === 'localisation' && (
            <form className={styles.adresseContainer} ref={containerRef}>
              <label htmlFor="adresse">Code postal ou ville :</label>
              <div>
                <SearchPlaceForMetier
                  onSelect={handleSearchPlaceSelect}
                  openList={openList}
                  setOpenList={setOpenList}
                />
              </div>
            </form>
          )}

          <Link to={`/map?metier=${specialiteId}`}>
            <button className={styles.mapButton}>Voir sur la carte</button>
          </Link>
        </div>
      </div>

      <ul className={styles.artisanList}>
        {currentArtisans.map(artisan => (
          <ArtisanCardInMetier key={artisan.id} artisan={artisan} />
        ))}
      </ul>

      <div className={styles.pagination}>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Précédent
        </button>
        <span>
          Page {currentPage} sur {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Suivant
        </button>
      </div>
    </div>
  );
};

Metier.propTypes = {
  specialiteId: PropTypes.string.isRequired
};

export default Metier;
