import React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { PrivacyPolicy } from './../components/RequiredInfos/PrivacyPolicy';

function PrivacyPolicyPage() {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb items={[{ label: 'Politique de confidentialité', path: '' }]} />
        <PrivacyPolicy />
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicyPage;
