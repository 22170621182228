import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchSpecialities, fetchSummarySpecialities } from '../../redux/specialitiesSlice';
import styles from './NosArtisans.module.css';

const NosArtisans = () => {
  const dispatch = useDispatch();
  const { specialities, summarySpecialities, isLoading, error } = useSelector(state => state.specialities);

  // Ne récupérer les spécialités que si elles ne sont pas déjà chargées
  useEffect(() => {
    if (!specialities || specialities.length === 0) {
      dispatch(fetchSpecialities());
      dispatch(fetchSummarySpecialities());
    }
  }, [dispatch, specialities]);

  if (isLoading) return <div>Chargement en cours...</div>;
  if (error) return <div>Erreur de chargement des corps de métier</div>;

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Faites Confiance à des ARTISANS Vérifiés et Qualifiés</h1>
      <h2 className={styles.subtitle}>
        Nous sélectionnons les meilleurs artisans de votre région pour vous garantir un service de qualité
      </h2>

      {/* Affichage en grille de 4 cartes par ligne */}
      <div className={styles.grid}>
        {summarySpecialities?.map(speciality => (
          <SpecialityCard key={speciality.id} speciality={speciality} specialities={specialities} />
        ))}
      </div>
    </div>
  );
};

// Composant enfant pour chaque carte d'artisan
const SpecialityCard = memo(({ speciality, specialities }) => {
  // Mémoriser la spécialité correspondante pour éviter des recherches répétées
  const specialityData = useMemo(
    () => specialities?.find(spe => spe.metier === speciality.metier),
    [specialities, speciality.metier]
  );

  return (
    <div className={styles.card}>
      <Link to={`/metier/${speciality.id}`}>
        {specialityData && (
          <img
            className={styles.image}
            src={`data:${specialityData.mimeType};base64,${specialityData.imageMetier}`}
            alt={speciality.metier}
            loading="lazy" // Lazy loading des images
          />
        )}
        <div className={styles.metier}>{speciality.metier}</div>
      </Link>
    </div>
  );
});

SpecialityCard.displayName = 'SpecialityCard';
SpecialityCard.propTypes = {
  speciality: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mimeType: PropTypes.string,
    imageMetier: PropTypes.string,
    metier: PropTypes.string
  }).isRequired,
  index: PropTypes.number,
  specialities: PropTypes.arrayOf(
    PropTypes.shape({
      mimeType: PropTypes.string,
      imageMetier: PropTypes.string
    })
  )
};

export default NosArtisans;
