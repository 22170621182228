import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import SearchPlaceForGeo from './../SearchPlace/SearchPlaceForGeo';
import SearchSpeciality from './../SearchPlace/SearchSpeciality';
import styles from './IntroductionSection.module.css';

const IntroductionSection = memo(() => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [openList, setOpenList] = useState(null);

  const navigate = useNavigate();
  const containerRef = useRef(null);

  // Fermer les listes déroulantes si on clique en dehors
  useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpenList(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSecteurSubmit = event => {
    event.preventDefault();
    const path = selectedSuggestion
      ? `/map?lat=${selectedSuggestion.geometry.coordinates[1]}&lon=${selectedSuggestion.geometry.coordinates[0]}`
      : '/map';
    navigate(path);
  };

  const handleSpecialitySubmit = event => {
    event.preventDefault();
    const path = selectedSpeciality ? `/metier/${selectedSpeciality.id}` : '/metiers';
    navigate(path);
  };

  const handleSpecialitySelect = useCallback(speciality => {
    setSelectedSpeciality(speciality);
    setOpenList(null);
  }, []);

  const handleSearchPlaceSelect = useCallback(suggestion => {
    setSelectedSuggestion(suggestion);
    setOpenList(null);
  }, []);

  const settings = useMemo(
    () => ({
      dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false
    }),
    []
  );

  return (
    <section className={styles.introSection}>
      <div className={styles.textContainer}>
        <h1 className={styles.mainTitle}>ProxiTravaux</h1>
        <h4 className={styles.introText}>
          TROUVEZ UN ARTISAN DE CONFIANCE PRÈS DE CHEZ VOUS, DÉCOUVREZ DES ARTISANS QUALIFIÉS POUR TOUS VOS PROJETS DE
          CONSTRUCTION, RÉNOVATION, ET AMÉNAGEMENT.
        </h4>
        <div className={styles.buttonContainer} ref={containerRef}>
          <form className={styles.searchForm} onSubmit={handleSpecialitySubmit}>
            <SearchSpeciality onSelect={handleSpecialitySelect} openList={openList} setOpenList={setOpenList} />
            <button type="submit" className={styles.searchButton}>
              Chercher
            </button>
          </form>
          <form className={styles.searchForm} onSubmit={handleSecteurSubmit}>
            <SearchPlaceForGeo onSelect={handleSearchPlaceSelect} openList={openList} setOpenList={setOpenList} />
            <button type="submit" className={styles.searchButton}>
              Chercher
            </button>
          </form>
        </div>
      </div>
      {
        <div className={styles.carouselContainer}>
          <Slider {...settings}>
            <div>
              <img
                src="assets/proxitravaux-reno-1920.webp"
                srcSet="
                assets/proxitravaux-reno-480.webp 480w,
                assets/proxitravaux-reno-800.webp 800w,
                assets/proxitravaux-reno-1200.webp 1200w,
                assets/proxitravaux-reno-1920.webp 1920w
                "
                sizes="(max-width: 480px) 480px,
                     (max-width: 800px) 800px,
                     (max-width: 1200px) 1200px,
                     1920px"
                alt="Artisan 1"
                className={styles.carouselImage}
                loading="lazy"
              />
            </div>
            <div>
              <img
                src="assets/proxitravaux-presdechezmoi-1920.webp"
                srcSet="
                assets/proxitravaux-presdechezmoi-480.webp 480w,
                assets/proxitravaux-presdechezmoi-800.webp 800w,
                assets/proxitravaux-presdechezmoi-1200.webp 1200w,
                assets/proxitravaux-presdechezmoi-1920.webp 1920w
                "
                sizes="(max-width: 480px) 480px,
                     (max-width: 800px) 800px,
                     (max-width: 1200px) 1200px,
                     1920px"
                alt="Artisan 2"
                className={styles.carouselImage}
                loading="lazy"
              />
            </div>
            <div>
              <img
                src="assets/proxitravaux-artisans.webp"
                srcSet="
              assets/proxitravaux-artisans-480.webp 480w,
              assets/proxitravaux-artisans-800.webp 800w,
              assets/proxitravaux-artisans-1200.webp 1200w,
              assets/proxitravaux-artisans-1920.webp 1920w
              "
                sizes="(max-width: 480px) 480px,
                   (max-width: 800px) 800px,
                   (max-width: 1200px) 1200px,
                   1920px"
                alt="Artisan 3"
                className={styles.carouselImage}
                loading="lazy"
              />
            </div>
          </Slider>
        </div>
      }
    </section>
  );
});

IntroductionSection.displayName = 'IntroductionSection';

export default IntroductionSection;
