import React from 'react';

import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import InfoLmnp from '../components/InfoDocuments/InfoLmnp';

const InfoLmnpPage = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb
          items={[
            { label: 'Infos et aides', path: '/infos-et-aides' },
            { label: 'Le LMNP', path: '' }
          ]}
        />
        <InfoLmnp />
      </div>
      <Footer />
    </div>
  );
};

export default InfoLmnpPage;
