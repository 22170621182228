import React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import LMPInfo from '../components/InfoDocuments/LMPInfo';

function LMPInfoPage() {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb
          items={[
            { label: 'Infos et aides', path: '/infos-et-aides' },
            { label: 'La Location Meublée Professionnelle', path: '' }
          ]}
        />
        <LMPInfo />
      </div>
      <Footer />
    </div>
  );
}

export default LMPInfoPage;
