import React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import InfosEtAides from './../components/InfosEtAides/InfosEtAides';

function InfosEtAidesPage() {
  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrumb items={[{ label: 'Infos et aides', path: '' }]} />
        <InfosEtAides />
      </div>
      <Footer />
    </div>
  );
}

export default InfosEtAidesPage;
