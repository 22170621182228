import React, { memo } from 'react';
import styles from './InfoDocuments.module.css';

const InvestmentStrategies = memo(() => {
  return (
    <div className={styles.InfoAideContainer}>
      <h1>Comparatif des Stratégies d’Investissement Immobilier</h1>
      <p>
        Investir dans l’immobilier est une stratégie efficace pour générer des revenus passifs et faire croître son
        patrimoine. Cependant, toutes les stratégies ne se valent pas selon vos objectifs et vos contraintes. Voici un
        comparatif des trois grandes stratégies d’investissement : le marchand de bien (achat/revente), la location
        longue durée, et la location courte durée (Airbnb).
      </p>

      <h2>Le marchand de bien (Achat/Revente) : Maximiser les gains à court terme</h2>
      <p>
        L’achat/revente est une stratégie d’investissement immobilier dynamique, particulièrement adaptée à ceux qui
        cherchent à réaliser des bénéfices rapides. Elle repose sur l’acquisition d’un bien à rénover ou sous-évalué, la
        valorisation de ce bien grâce à des travaux ou une optimisation, puis sa revente à un prix supérieur.
      </p>
      <ul>
        <li>
          Recherche d’un bien sous-évalué (besoin de travaux, mauvaise classe énergétique, potentiel d’optimisation).
        </li>
        <li>Valorisation du bien par la rénovation ou la mise en avant de ses atouts.</li>
        <li>Revente rapide pour maximiser la plus-value.</li>
      </ul>
      <h3>Les avantages de cette stratégie :</h3>
      <ul>
        <li>Une liquidité rapide : Les gains se concrétisent souvent en moins d’un an.</li>
        <li>Pas de gestion locative : Vous évitez les contraintes liées à la gestion d’un bien loué.</li>
      </ul>
      <h3>Les inconvénients et risques :</h3>
      <ul>
        <li>
          Une fiscalité à la revente : Les plus-values immobilières sont soumises à l’impôt sur le revenu ou sur les
          sociétés.
        </li>
        <li>L’incertitude liée à la revente : Les conditions du marché immobilier peuvent évoluer.</li>
        <li>Une exigence de compétences : Expertise en rénovation et gestion des artisans.</li>
      </ul>

      <h2>La location longue durée : Une rentabilité stable et prévisible</h2>
      <p>
        La location longue durée est une stratégie d’investissement immobilier classique qui repose sur la mise à
        disposition d’un bien immobilier pour une période prolongée, généralement de plusieurs années. Elle offre une
        rentabilité régulière, idéale pour les investisseurs recherchant des revenus passifs à faible risque.
      </p>
      <h3>Les avantages de la location longue durée :</h3>
      <ul>
        <li>Des revenus réguliers et prévisibles.</li>
        <li>Une fiscalité avantageuse : Des régimes fiscaux adaptés selon le type de location choisie.</li>
        <li>Une plus-value sur le long terme.</li>
      </ul>
      <h3>Les inconvénients et limites :</h3>
      <ul>
        <li>Des rendements souvent limités.</li>
        <li>Un risque d’impayés malgré les assurances loyers impayés.</li>
        <li>Des frais d’entretiens et de mise aux normes.</li>
      </ul>

      <h2>La Location courte durée : Maximiser la rentabilité à court terme</h2>
      <p>
        La location courte durée, ou location saisonnière, consiste à louer un bien immobilier pour de courtes périodes
        via des plateformes comme Airbnb. Cette stratégie séduit par son potentiel de rentabilité élevé, mais elle
        nécessite un investissement en temps et une compréhension des contraintes légales.
      </p>
      <h3>Les avantages de la location courte durée :</h3>
      <ul>
        <li>Des rendements élevés, surtout pendant la haute saison touristique.</li>
        <li>La flexibilité d’usage : L’investisseur peut utiliser son bien personnellement entre deux locations.</li>
      </ul>
      <h3>Les inconvénients et contraintes :</h3>
      <ul>
        <li>Une gestion intensive, impliquant accueil, ménage, et suivi des réservations.</li>
        <li>Une réglementation stricte avec des restrictions sur le nombre de jours de location.</li>
        <li>Une fiscalité lourde et une concurrence accrue.</li>
      </ul>

      <h2>Conclusion</h2>
      <p>Voici un tableau comparatif de chaque stratégie :</p>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Critères</th>
              <th>Achat/Revente</th>
              <th>Location Longue Durée</th>
              <th>Location Courte Durée</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rentabilité</td>
              <td>Élevée</td>
              <td>Modérée</td>
              <td>Élevée</td>
            </tr>
            <tr>
              <td>Risque</td>
              <td>Moyen</td>
              <td>Faible</td>
              <td>Moyen</td>
            </tr>
            <tr>
              <td>Gestion</td>
              <td>Moyenne</td>
              <td>Faible</td>
              <td>Importante</td>
            </tr>
            <tr>
              <td>Fiscalité</td>
              <td>Lourde</td>
              <td>Optimisable</td>
              <td>Lourde</td>
            </tr>
            <tr>
              <td>Profil idéal</td>
              <td>Actif et expérimenté</td>
              <td>Patient et prudent</td>
              <td>Dynamique et impliqué</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        Le choix de la stratégie dépend de vos objectifs financiers, de votre tolérance au risque et du temps que vous
        pouvez consacrer à la gestion. Il n’existe pas de bon ou de mauvais choix, chaque stratégie ayant ses avantages
        et ses inconvénients.
      </p>
    </div>
  );
});

InvestmentStrategies.displayName = 'InvestmentStrategies';
export default InvestmentStrategies;
