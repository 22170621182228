import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Metier from '../components/Metier/Metier';

const MetierPage = () => {
  const { specialiteId } = useParams();

  return (
    <div>
      <Header />
      <div className="page-content">
        {/* Fil d'Ariane géré à l'intérieur de la page car nécessite le nom de la spécialité */}

        <Metier specialiteId={specialiteId} />
      </div>
      <Footer />
    </div>
  );
};

export default MetierPage;
