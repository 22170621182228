import React, { memo } from 'react';
import styles from './InfoDocuments.module.css';

const InfoLmnp = memo(() => {
  return (
    <div className={styles.InfoAideContainer}>
      <h1>La Location Meublée Non Professionnelle (LMNP)</h1>
      <p>
        La location meublée non professionnelle (LMNP) est une option attrayante pour les investisseurs immobiliers
        souhaitant bénéficier d&apos;un régime fiscal avantageux. Dans cette édition, nous explorerons les aspects clés
        de la fiscalité en LMNP et comment optimiser vos investissements.
      </p>

      <h2>Qu&apos;est-ce que le LMNP ?</h2>
      <p>
        Le statut de Loueur en Meublé Non Professionnel (LMNP) s&apos;applique aux particuliers louant un ou plusieurs
        biens immobiliers meublés sans en faire leur activité principale, c&apos;est-à-dire que leurs revenus locatifs
        ne dépassent pas 23 000 euros ou dans le cas où il dépasse ce plafond, il faut que ces revenus locatifs soient
        inférieurs à vos autres sources de revenus (salaires, pensions, etc.).
      </p>
      <p>
        Le LMNP offre des avantages fiscaux intéressants, notamment grâce à deux régimes fiscaux : le régime micro-BIC
        et le régime réel.
      </p>

      <h2>Le Micro-BIC et le Régime réel</h2>
      <p>
        En LMNP (Location Meublée Non Professionnelle), il existe deux régimes fiscaux pour déclarer vos revenus
        locatifs : le régime Micro-BIC et le régime Réel.
      </p>
      <p>
        Le régime Micro-BIC s&apos;applique si vos revenus locatifs bruts sont inférieurs à 77 700 € par an. Avec ce
        régime, vous bénéficiez d&apos;un abattement forfaitaire de 50 % sur vos revenus locatifs pour couvrir les
        charges, sans avoir besoin de détailler ces dernières. Cela simplifie la déclaration fiscale, car vous
        n&apos;avez pas à justifier vos dépenses.
      </p>
      <p>
        En revanche, le régime Réel s&apos;impose si vos revenus locatifs bruts dépassent 77 700 € par an, mais il est
        également possible d&apos;opter pour ce régime en dessous de ce seuil.
      </p>
      <p>
        Le principal avantage du régime réel est la possibilité de déduire les charges réelles, telles que les intérêts
        d&apos;emprunt, les travaux, les assurances, les frais de gestion et autres dépenses liées au bien. De plus, ce
        régime permet d&apos;amortir sur plusieurs années la valeur du bien immobilier, le mobilier, les travaux et les
        frais d&apos;acquisition, ce qui peut réduire significativement votre revenu imposable. Cependant, il nécessite
        une tenue de comptabilité détaillée et la déclaration précise des charges réelles.
      </p>

      <h3>Les principales catégories d&apos;amortissement</h3>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Catégorie</th>
              <th>Durée d&apos;amortissement</th>
              <th>Explication</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Le bien immobilier</td>
              <td>20 à 30 ans</td>
              <td>La valeur du bâti, excluant le terrain, est amortie sur cette période.</td>
            </tr>
            <tr>
              <td>Les frais d&apos;acquisition</td>
              <td>20 à 30 ans</td>
              <td>Les frais liés à l&apos;achat du bien sont amortis sur la même durée que le bâti.</td>
            </tr>
            <tr>
              <td>Le mobilier</td>
              <td>5 à 10 ans</td>
              <td>Les équipements électroménagers ou le mobilier sont amortis sur une période plus courte.</td>
            </tr>
            <tr>
              <td>Les travaux</td>
              <td>Variable</td>
              <td>Les travaux lourds (20-30 ans) et légers (5-10 ans) sont amortis sur des durées distinctes.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3>Charges déductibles en régime réel</h3>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Type de charge</th>
              <th>Explication</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Frais de gestion et d&apos;administration</td>
              <td>Honoraires des agences immobilières, frais de syndic, etc.</td>
            </tr>
            <tr>
              <td>Intérêts d&apos;emprunt</td>
              <td>Les intérêts des prêts sont déductibles, ce qui peut représenter une part importante des charges.</td>
            </tr>
            <tr>
              <td>Assurances</td>
              <td>
                Primes d&apos;assurance liées au bien, comme l&apos;assurance PNO ou les garanties contre loyers
                impayés.
              </td>
            </tr>
            <tr>
              <td>Taxes foncières</td>
              <td>
                Le montant de la taxe foncière est déductible, sauf la part relative à la taxe d&apos;enlèvement des
                ordures ménagères.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3>Exemple de calcul en LMNP</h3>
      <h4>Régime Micro-BIC</h4>
      <p>Dans le régime Micro-BIC, un abattement forfaitaire de 50 % est appliqué aux revenus locatifs.</p>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Revenus</th>
              <th>Calcul</th>
              <th>Montant</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Revenus locatifs bruts</td>
              <td>12 000 €</td>
              <td>12 000 €</td>
            </tr>
            <tr>
              <td>Abattement forfaitaire (50 %)</td>
              <td>12 000 € × 50 %</td>
              <td>6 000 €</td>
            </tr>
            <tr>
              <td>Revenus imposables</td>
              <td>12 000 € - 6 000 €</td>
              <td>6 000 €</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4>Régime Réel</h4>
      <p>Le régime réel permet de déduire les charges réelles et d&apos;amortir le bien et les travaux.</p>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Revenus</th>
              <th>Calcul</th>
              <th>Montant</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Revenus locatifs</td>
              <td>12 000 €</td>
              <td>12 000 €</td>
            </tr>
            <tr>
              <td>Total des charges déductibles</td>
              <td>2 000 € (intérêts) + 1 000 € (frais) + 8 000 € (amortissements)</td>
              <td>11 000 €</td>
            </tr>
            <tr>
              <td>Revenus imposables</td>
              <td>12 000 € - 11 000 €</td>
              <td>1 000 €</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2>La Plus-Value Immobilière</h2>
      <p>
        Les plus-values issues de la vente d&apos;un bien en LMNP sont imposées à 19 % d&apos;impôt sur le revenu et
        17,2 % de prélèvements sociaux. Un abattement progressif s&apos;applique en fonction de la durée de détention.
      </p>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Durée de détention</th>
              <th>Abattement pour l&apos;impôt sur le revenu</th>
              <th>Abattement pour les prélèvements sociaux</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Moins de 6 ans</td>
              <td>0 %</td>
              <td>0 %</td>
            </tr>
            <tr>
              <td>Entre 6 et 21 ans</td>
              <td>6 % par an à partir de la 6e année</td>
              <td>1,65 % par an à partir de la 6e année</td>
            </tr>
            <tr>
              <td>22e année</td>
              <td>4 %</td>
              <td>1,60 %</td>
            </tr>
            <tr>
              <td>Au-delà de 22 ans</td>
              <td>Exonération totale</td>
              <td>9 % par an jusqu&apos;à la 30e année</td>
            </tr>
            <tr>
              <td>À partir de 30 ans</td>
              <td>Exonération totale</td>
              <td>Exonération totale</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2>Conclusion</h2>
      <p>
        Le statut LMNP offre des avantages fiscaux substantiels pour les investisseurs en immobilier meublé. Une bonne
        compréhension des régimes fiscaux disponibles et une planification minutieuse permettent d&apos;optimiser vos
        revenus locatifs et d&apos;améliorer la rentabilité de votre investissement.
      </p>
    </div>
  );
});

InfoLmnp.displayName = 'InfoLmnp';

export default InfoLmnp;
