// src/store/artisanDetailsSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDetailArtisanByArtisanId, getDetailArtisanByArtisanIdAndType } from '../services/Api';

// Action asynchrone pour récupérer les détails des artisans
export const fetchArtisanDetails = createAsyncThunk('artisan/fetchDetails', async id => {
  const artisanDetailsFromLocalStorage = localStorage.getItem(`artisanDetails-${id}`);
  if (artisanDetailsFromLocalStorage) {
    // Si les données sont dans le localStorage, on les retourne directement
    return JSON.parse(artisanDetailsFromLocalStorage);
  }

  // Effectuer l'appel API pour récupérer les détails des artisans, y compris les fichiers binaires
  const response = await getDetailArtisanByArtisanId(id);

  try {
    // Sauvegarder les données dans le localStorage pour les réutiliser plus tard
    localStorage.setItem(`artisanDetails-${id}`, JSON.stringify(response));
  } catch (e) {
    console.warn('LocalStorage indisponible : ' + e);
  }
  return response; // Renvoie la liste des artisans avec leurs fichiers
});

// Action pour mettre à jour les détails d'un artisan
export const updateData = createAsyncThunk('artisan/updateDetail', async id => {
  // Appel API pour récupérer les détails mis à jour de l'artisan
  const response = await getDetailArtisanByArtisanId(id);
  try {
    localStorage.setItem(`artisanDetails-${id}`, JSON.stringify(response));
  } catch (e) {
    console.warn('LocalStorage indisponible : ' + e);
  }
  return response;
});

// Action asynchrone pour récupérer les détails des artisans par type
export const fetchArtisanDetailsByType = createAsyncThunk('artisan/fetchDetailsByType', async (id, type) => {
  const artisanDetailsFromLocalStorage = localStorage.getItem(`artisanDetailsType-${id}-image`);
  if (artisanDetailsFromLocalStorage) {
    // Si les données sont dans le localStorage, on les retourne directement
    return JSON.parse(artisanDetailsFromLocalStorage);
  }

  // Effectuer l'appel API pour récupérer les détails des artisans, y compris les fichiers binaires
  const response = await getDetailArtisanByArtisanIdAndType(id, type);

  try {
    // Sauvegarder les données dans le localStorage pour les réutiliser plus tard
    localStorage.setItem(`artisanDetailsType-${id}-image`, JSON.stringify(response));
  } catch (e) {
    console.warn('LocalStorage indisponible : ' + e);
  }

  return response;
});

const artisanDetailsSlice = createSlice({
  name: 'artisanDetail',
  initialState: {
    artisanDetails: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchArtisanDetails.pending, state => {
        state.loading = true;
      })
      .addCase(fetchArtisanDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.artisanDetails = action.payload;
      })
      .addCase(fetchArtisanDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchArtisanDetailsByType.pending, state => {
        state.loading = true;
      })
      .addCase(fetchArtisanDetailsByType.fulfilled, (state, action) => {
        state.loading = false;
        state.artisanDetails = action.payload;
      })
      .addCase(fetchArtisanDetailsByType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateData.pending, state => {
        state.loading = true;
      })
      .addCase(updateData.fulfilled, (state, action) => {
        state.loading = false;
        // Mettez à jour les détails de l'artisan dans le store
        state.artisanDetails = action.payload;
      })
      .addCase(updateData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default artisanDetailsSlice.reducer;
