import { configureStore } from '@reduxjs/toolkit';
import artisanDetailsReducer from './artisansDetailsSlice';
import authReducer from './authSlice';
import specialitiesReducer from './specialitiesSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    specialities: specialitiesReducer,
    artisanDetails: artisanDetailsReducer
  }
});

export default store;
