import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { checkToken } from '../redux/authSlice';

const PrivatePages = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userClaims, isLoading } = useSelector(state => state.auth);

  useEffect(() => {
    if (!userClaims) {
      dispatch(checkToken());
    }
  }, [dispatch, userClaims]);

  if (isLoading) return <div>Chargement...</div>;

  if (!userClaims && location.pathname.includes('backoffice')) {
    return <Navigate to="/backoffice" />;
  } else if (!userClaims) {
    return <Navigate to="/admin" />;
  } else {
    return <Outlet />;
  }
};

export default PrivatePages;
