import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userLogin } from '../../redux/authSlice';
import styles from './Login.module.css';

const PasswordField = ({ value, onChange, showPassword, toggleShowPassword }) => (
  <div className={styles.passwordContainer}>
    <input
      type={showPassword ? 'text' : 'password'}
      id="password"
      name="password"
      value={value}
      onChange={onChange}
      required
      className={styles.inputFieldConnexion}
    />
    <button
      type="button"
      onClick={toggleShowPassword}
      className={styles.togglePassword}
      aria-label={showPassword ? 'Hide password' : 'Show password'}
    >
      {showPassword ? '👁️' : '🙈'}
    </button>
  </div>
);

PasswordField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const { isLoading, error } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = useCallback(e => {
    const { name, value } = e.target;
    setCredentials(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      const result = await dispatch(userLogin(credentials));
      if (result.meta.requestStatus === 'fulfilled') {
        const { role, artisanId } = result.payload;
        const isAdminRoute = location.pathname.includes('backoffice');
        const destination =
          role === 'ROLE_ARTISAN' ? `/admin/artisan/${artisanId}` : isAdminRoute ? '/backoffice/admin/home' : '/';
        navigate(destination);
        toast.success('Connexion réussie !');
      } else {
        toast.error("Échec de la connexion : mauvais nom d'utilisateur ou mot de passe.");
      }
    },
    [credentials, dispatch, location.pathname, navigate]
  );

  const handleForgotPassword = useCallback(() => {
    navigate('/forgot-password');
  }, [navigate]);

  return (
    <div className={styles.loginContainer}>
      <h2>Connexion</h2>
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        <div>
          <label htmlFor="username">Nom d&apos;utilisateur:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={credentials.username}
            onChange={handleChange}
            required
            className={styles.inputFieldConnexion}
          />
        </div>
        <div>
          <label htmlFor="password">Mot de passe:</label>
          <PasswordField
            value={credentials.password}
            onChange={handleChange}
            showPassword={showPassword}
            toggleShowPassword={() => setShowPassword(prev => !prev)}
          />
        </div>
        <button type="submit" disabled={isLoading} className={styles.connexionButton}>
          Se connecter
        </button>
        {error && <p className={styles.errorMessage}>{error}</p>}
      </form>
      <p className={styles.forgotPasswordText}>
        <button type="button" onClick={handleForgotPassword} className={styles.forgotPasswordButton}>
          Mot de passe oublié ?
        </button>
      </p>
    </div>
  );
};

export default Login;
