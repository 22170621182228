import React, { memo } from 'react';

const LocationNue = memo(() => {
  return (
    <div>
      <h1>La Fiscalité Immobilière en Location Nue</h1>
      <p>
        La location nue, ou location non meublée, est une forme courante d&apos;investissement immobilier en France. Cet
        article présente un tour d&apos;horizon des principales règles fiscales, des avantages et des stratégies pour
        optimiser la rentabilité des investissements en location nue.
      </p>

      <h2>Qu&apos;est-ce que la Location Nue ?</h2>
      <p>
        La location nue consiste à louer un bien immobilier sans meubles, à l&apos;exception des équipements de base
        (cuisine, salle de bains). Elle est souvent préférée pour sa simplicité et ses avantages fiscaux.
      </p>

      <h2>Les Revenus Fonciers</h2>
      <p>
        Les revenus générés par la location nue sont considérés comme des revenus fonciers et sont soumis à
        l&apos;impôt. Ces revenus comprennent les loyers perçus ainsi que les charges refacturées au locataire. Deux
        régimes fiscaux s&apos;appliquent à la déclaration de ces revenus :
      </p>

      <h3>Régime Micro-Foncier</h3>
      <p>Ce régime est applicable si les revenus fonciers bruts annuels sont inférieurs à 15 000 €.</p>
      <ul>
        <li>
          Avantage : abattement forfaitaire de 30 % sur les revenus bruts, couvrant les charges, avec une déclaration
          simplifiée.
        </li>
      </ul>

      <h3>Régime Réel</h3>
      <p>
        Obligatoire si les revenus fonciers bruts dépassent 15 000 €, mais également accessible par option pour les
        propriétaires ayant des charges importantes.
      </p>
      <ul>
        <li>Permet de déduire les charges réelles (travaux, intérêts d&apos;emprunt, assurances, frais de gestion).</li>
        <li>Nécessite une comptabilité rigoureuse.</li>
      </ul>

      <h2>Les Charges Déductibles en Régime Réel</h2>
      <table border="1" cellPadding="10">
        <thead>
          <tr>
            <th>Type de Charge</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Frais de gestion et d&apos;administration</td>
            <td>
              Honoraires des agences, frais de syndic, autres dépenses administratives liées à la gestion du bien.
            </td>
          </tr>
          <tr>
            <td>Intérêts d&apos;emprunt</td>
            <td>Déductibles pour les prêts contractés pour l&apos;acquisition du bien ou des travaux.</td>
          </tr>
          <tr>
            <td>Assurances</td>
            <td>Primes d&apos;assurance liées au bien, telles que l&apos;assurance propriétaire non occupant.</td>
          </tr>
          <tr>
            <td>Taxes foncières</td>
            <td>
              Déductibilité totale de la taxe foncière, sauf pour la part liée à la taxe d&apos;enlèvement des ordures
              ménagères.
            </td>
          </tr>
          <tr>
            <td>Les travaux</td>
            <td>
              Déductibles pour l&apos;entretien, la réparation ou l&apos;amélioration du bien (hors construction ou
              agrandissement).
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Déficit Foncier</h2>
      <p>
        En cas de déficit foncier (lorsque les charges déductibles dépassent les revenus fonciers), il est possible
        d&apos;imputer ce déficit sur le revenu global, dans la limite de 10 700 € par an. L&apos;excédent peut être
        reporté sur les revenus fonciers des 10 années suivantes.
      </p>

      <h3>Exemple Pratique</h3>
      <p>
        Prenons l&apos;exemple d&apos;un bien immobilier acheté à 100 000 €, avec 50 000 € de travaux, loué pour 1 000 €
        par mois (soit 12 000 € de revenus annuels).
      </p>
      <ul>
        <li>
          <strong>Régime Micro-Foncier</strong> :<br />
          Revenus fonciers : 12 000 €<br />
          Abattement de 30 % : 3 600 €<br />
          Revenus nets imposables : 8 400 €
        </li>
        <li>
          <strong>Régime Réel</strong> :<br />
          Travaux amortis sur 10 ans : 5 000 €<br />
          Charges déductibles : 2 000 € (intérêts) + 1 000 € (autres frais) + 5 000 € (amortissements) = 8 000 €<br />
          Revenus nets imposables : 4 000 €
        </li>
      </ul>

      <h2>Plus-Value Immobilière</h2>
      <p>
        La plus-value réalisée lors de la vente d&apos;un bien en location nue est soumise à un impôt sur le revenu de
        19 % et des prélèvements sociaux de 17,2 %. Un abattement progressif s&apos;applique selon la durée de détention
        du bien.
      </p>

      <table border="1" cellPadding="10">
        <thead>
          <tr>
            <th>Durée de détention</th>
            <th>Abattement Impôt sur le Revenu</th>
            <th>Abattement Prélèvements Sociaux</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Moins de 6 ans</td>
            <td>0 %</td>
            <td>0 %</td>
          </tr>
          <tr>
            <td>6 à 21 ans</td>
            <td>6 % par an</td>
            <td>1,65 % par an</td>
          </tr>
          <tr>
            <td>À partir de 22 ans</td>
            <td>Exonération totale</td>
            <td>9 % par an jusqu&apos;à la 30e année</td>
          </tr>
          <tr>
            <td>Après 30 ans</td>
            <td>Exonération totale</td>
            <td>Exonération totale</td>
          </tr>
        </tbody>
      </table>

      <h3>Exemple de Calcul de Plus-Value</h3>
      <p>
        Supposons un bien acheté à 100 000 € et vendu à 200 000 € après 20 ans. La plus-value brute est de 100 000 €.
      </p>
      <ul>
        <li>
          <strong>Abattement Impôt sur le Revenu</strong> : 90 % (15 ans × 6 %), donc la base imposable est de 10 000 €.
        </li>
        <li>
          <strong>Impôt sur le revenu</strong> : 10 000 € × 19 % = 1 900 €
        </li>
        <li>
          <strong>Abattement Prélèvements Sociaux</strong> : 24,75 % (15 ans × 1,65 %), donc la base imposable est de 75
          250 €.
        </li>
        <li>
          <strong>Prélèvements sociaux</strong> : 75 250 € × 17,2 % = 12 949 €
        </li>
        <li>
          <strong>Imposition Totale</strong> : 1 900 € + 12 949 € = 14 849 €
        </li>
      </ul>

      <h2>Conclusion</h2>
      <p>
        Investir en location nue peut offrir des avantages fiscaux considérables si vous utilisez correctement les
        régimes fiscaux disponibles. Une gestion rigoureuse des charges et des travaux, ainsi qu&apos;une planification
        fiscale appropriée, sont essentielles pour optimiser la rentabilité de votre investissement immobilier.
      </p>
    </div>
  );
});

LocationNue.displayName = 'LocationNue';

export default LocationNue;
