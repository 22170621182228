import React, { memo } from 'react';
import styles from './InfoDocuments.module.css';

const FiscaliteImmobiliere = memo(() => {
  return (
    <div className={styles.InfoAideContainer}>
      <header>
        <h1>La Fiscalité Immobilière en SCI à l&apos;Impôt sur les Sociétés (IS)</h1>
      </header>
      <section>
        <p>
          Les SCI à l&apos;IS offrent des avantages fiscaux et une flexibilité de gestion intéressants pour les
          investisseurs immobiliers. Nous allons explorer les aspects clés de la fiscalité des SCI à l&apos;IS et
          comment optimiser vos investissements.
        </p>
      </section>

      <section>
        <h2>Qu&apos;est-ce qu&apos;une SCI à l&apos;IS ?</h2>
        <p>
          Une Société Civile Immobilière (SCI) est une structure juridique permettant la détention et la gestion de
          biens immobiliers par plusieurs associés. Lorsqu&apos;une SCI opte pour l&apos;impôt sur les sociétés (IS),
          ses bénéfices sont imposés selon le barème de l&apos;IS, et non à l&apos;impôt sur le revenu des associés.
        </p>
        <p>
          Pour créer une SCI, il est nécessaire qu&apos;il y ait au moins deux associés, que ce soit des personnes
          physiques (individus) ou des personnes morales (entreprises ou autres structures juridiques). Une SCI ne peut
          pas être constituée par une seule personne, car elle doit obligatoirement avoir des associés pour fonctionner,
          qu&apos;ils soient physiques ou moraux.
        </p>
      </section>

      <section>
        <h2>Le fonctionnement d&apos;une SCI</h2>
        <h3>La Gestion des Charges et l&apos;Amortissement</h3>
        <p>
          Être en SCI à l&apos;IS présente plusieurs avantages en matière de gestion des charges et
          d&apos;amortissement, notamment pour l&apos;optimisation fiscale. Voici les principales catégories
          d&apos;amortissement :
        </p>
        <ul>
          <li>
            <strong>Le bien immobilier :</strong>
            La valeur amortissable concerne uniquement le bâti, excluant la valeur du terrain, car celui-ci n&apos;est
            pas considéré comme dépréciable (il ne se dégrade pas dans le temps). Généralement, la valeur du bâti
            représente 80 à 90 % du prix total d&apos;acquisition du bien. Cet amortissement s&apos;étale sur une durée
            de 20 à 30 ans.
          </li>
          <li>
            <strong>Les frais d&apos;acquisition :</strong>
            Les frais liés à l&apos;achat du bien, comme les frais de notaire ou d&apos;agence, sont amortis sur la même
            durée que celle du bâti, soit 20 à 30 ans.
          </li>
          <li>
            <strong>Le mobilier :</strong>
            Les éléments meublant le bien, tels que les équipements électroménagers ou le mobilier, sont amortis sur une
            période plus courte, généralement entre 5 et 10 ans.
          </li>
          <li>
            <strong>Les travaux :</strong>
            Les travaux réalisés sur le bien sont amortis sur des durées variables :
            <ul>
              <li>
                Travaux lourds : Rénovations structurelles, toiture, refonte des installations électriques, etc. -
                amortis sur 20 à 30 ans.
              </li>
              <li>
                Travaux légers : Aménagements intérieurs, installation de systèmes de chauffage, etc. - amortis entre 5
                et 10 ans.
              </li>
            </ul>
          </li>
        </ul>

        <p>
          Ce système permet de répartir les dépenses importantes sur plusieurs années, diminuant chaque année le montant
          des revenus imposables.
        </p>

        <h3>Les principales charges déductibles</h3>
        <ul>
          <li>
            Frais de gestion et d&apos;administration, tels que les honoraires des agences immobilières, les frais de
            syndic.
          </li>
          <li>
            Intérêts d&apos;emprunt : Les intérêts des prêts contractés pour financer l&apos;acquisition du bien ou
            réaliser des travaux sont déductibles.
          </li>
          <li>
            Assurances : Les primes d&apos;assurance liées au bien, telles que l&apos;assurance propriétaire non
            occupant (PNO), sont également déductibles.
          </li>
          <li>Taxes foncières : Le montant de la taxe foncière peut être intégralement déduit des revenus locatifs.</li>
        </ul>
      </section>

      <section>
        <h2>Fiscalité des Revenus Locatifs</h2>
        <p>
          Les bénéfices d&apos;une SCI à l&apos;IS sont soumis à l&apos;impôt sur les sociétés. Un taux réduit de 15 %
          s&apos;applique sur les bénéfices jusqu&apos;à 38 120 €, tandis que le taux normal de 25 % s&apos;applique aux
          bénéfices supérieurs à ce montant.
        </p>
        <h3>Exemple</h3>
        <p>
          Prenons un exemple d&apos;un bien immobilier acheté à 100 000 € avec 50 000 € de travaux, loué pour 1 000 €
          par mois, soit 12 000 € de revenus fonciers annuels.
        </p>
        <ul>
          <li>Intérêts d&apos;emprunt : 2 000 € par an</li>
          <li>Assurances et taxes foncières : 1 000 € par an</li>
          <li>Amortissement du bien immobilier : 135 000 € / 30 ans = 4 500 € par an</li>
          <li>Amortissement des travaux : 45 000 € / 10 ans = 4 500 € par an</li>
        </ul>
        <p>Calcul pour la SCI à l&apos;IS :</p>
        <ul>
          <li>Revenus fonciers bruts : 18 000 €</li>
          <li>Total des charges déductibles : 12 000 €</li>
          <li>Revenus fonciers nets : 6 000 €</li>
          <li>Impôt sur les Sociétés (IS) à appliquer : 15% sur 6 000 € = 870 €</li>
        </ul>
      </section>

      <section>
        <h2>Plus-Value Immobilière</h2>
        <p>
          La plus-value réalisée lors de la vente d&apos;un bien détenu par une SCI à l&apos;IS est soumise à
          l&apos;impôt sur les sociétés. Calcul de la plus-value = Prix de vente - Valeur nette comptable (prix
          d&apos;achat - amortissements pratiqués).
        </p>
        <h3>Exemple</h3>
        <p>
          Le bien a été acheté pour un prix de 150 000 €, avec des travaux réalisés pour un montant de 50 000 € soit 200
          000 €. Le bien est revendu au bout de 20 ans à 250 000 €.
        </p>
        <ul>
          <li>Amortissement du bien immobilier : 135 000 € sur 20 ans = 90 000 €</li>
          <li>
            Amortissement des travaux : 50 000 € sur 10 ans, donc après 20 ans ces travaux sont entièrement amortis.
          </li>
        </ul>
        <p>Calcul de la plus-value : 250 000 € - 60 000 € (valeur nette fiscale) = 190 000 €.</p>
        <h3>Imposition de la Plus-Value</h3>
        <p>L&apos;impôt sur la plus-value est calculé au taux de 25 %, soit : 190 000 € x 25 % = 47 500 €.</p>
      </section>

      <section>
        <h2>Conclusion</h2>
        <p>
          La SCI à l&apos;IS peut être particulièrement avantageuse pendant la détention d&apos;un bien, car elle permet
          de déduire de nombreuses charges, comme les travaux, les intérêts d&apos;emprunt, ou les amortissements.
          Toutefois, il faut être vigilant lors de la revente du bien, car l&apos;amortissement pratiqué pendant la
          détention peut réduire la valeur nette comptable, ce qui peut entraîner une plus-value imposable plus
          importante.
        </p>
        <p>
          En outre, la distribution des bénéfices sous forme de dividendes entraîne une imposition supplémentaire via la
          flat tax de 30 %.
        </p>
      </section>
    </div>
  );
});

FiscaliteImmobiliere.displayName = 'FiscaliteImmobiliere';
export default FiscaliteImmobiliere;
